import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  CircularProgress,
  Typography,
  FormHelperText,
  Dialog,
  Box,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import { SketchPicker } from 'react-color';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LocationPicker } from '../../../common/components';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { Pin, Map, Location, useGetMapLazyQuery } from '../../../graphql';
import InputLabel from '@mui/material/InputLabel';
import ImageDropzone from '../../../common/components/ImageDropzone';
import { ErrorMessage } from '@hookform/error-message';
import NodesEdit from './NodesEdit';
import { checkPermission } from '../../../utils/check-permission';

const FeatureForm = (props: {
  onSubmit: (arg0: any) => void;
  loading: boolean;
  title: string;
  defaultValues?: any;
  maps?: Partial<Map>[];
  pins: Partial<Pin>[];
  location?: Partial<Location>;
  placeId?: string;
  preSelectedMap?: Pick<
    Map,
    'id' | 'name' | 'floor' | 'createdAt' | 'updatedAt' | 'tileUrl' | 'initialZoom' | 'minZoom' | 'maxZoom'
  >;
}) => {
  const { onSubmit, loading, title, defaultValues, maps, pins, location, preSelectedMap } = props;
  const canUpdateLite = checkPermission('update_lite_features_all');

  const [open, setOpen] = useState(false);
  const [getMap, { data: mapData }] = useGetMapLazyQuery();
  useEffect(() => {
    if (defaultValues?.map?.id) {
      getMap({ variables: { id: defaultValues.map.id } });
    }
  }, []);

  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().required(t('isRequired', { field: t('name') })),
    center: yup.mixed(),
    fromZoom: yup.number(),
    toZoom: yup.number()
      .test('is-greater-than-fromZoom', t('toZoomMustBeGreaterThanFromZoom'), function(value) {
        const { fromZoom } = this.parent;
        return fromZoom == 0 || !value || value >= fromZoom;
      }).nullable(),
    map: !preSelectedMap ? yup.object().required(t('isRequired', { field: t('map') })) : undefined,
    pin: yup.object().nullable(),
    nodes: yup.array(yup.mixed()),
  });
  const mapsOptions = maps?.map((map) => {
    return { value: map.id, label: map.name };
  });
  const pinsOptions = pins.map((pin) => {
    return { value: pin.id, label: pin.name };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          center: { lat: defaultValues.centerGeo.latitude, lng: defaultValues.centerGeo.longitude },
          map: mapsOptions?.find((option: any) => option.value === defaultValues?.map?.id) || undefined,
          pin: pinsOptions.find((option: any) => option.value === defaultValues?.pin?.id) || undefined,
          nodes: defaultValues?.nodes?.map((node: any) => ({
            lat: node?.pointGeo?.latitude,
            lng: node?.pointGeo?.longitude,
          })),
        }
      : { name: location?.name || '', fromZoom: 0, nodes: [] },
  });
  const selectedMap = watch('map');
  const center = watch('center');
  const getSelectedMap = (): Partial<Map> => {
    if (preSelectedMap) {
      return preSelectedMap;
    } else {
      return maps.find((m) => m.id === selectedMap.value);
    }
  };

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        const dataToSubmit = data;
        dataToSubmit.mapId = preSelectedMap ? preSelectedMap.id : dataToSubmit.map?.value;
        delete dataToSubmit.map;
        if (dataToSubmit.pin) {
          dataToSubmit.pinId = dataToSubmit.pin.value;
          delete dataToSubmit.pin;
        } else {
          dataToSubmit.pinId = null;
        }
        if (data.center) {
          dataToSubmit.center = [data.center.lat, data.center.lng];
        } else {
          const currentMap = getSelectedMap();
          const centerLat = (currentMap.pointNeGeo.latitude + currentMap.pointSwGeo.latitude) / 2;
          const centerLng = (currentMap.pointNeGeo.longitude + currentMap.pointSwGeo.longitude) / 2;
          dataToSubmit.center = [centerLat, centerLng];
        }

        if (!canUpdateLite) {
          delete dataToSubmit.color;
        }
        onSubmit(dataToSubmit);
      })}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={1} direction="column" sx={{ width: '100%' }}>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                label={capitalize('name')}
                type="text"
                variant="outlined"
                {...register('name')}
                error={Boolean(errors.name)}
                helperText={errors.name?.message || ' '}
              />
            </Grid>
            {preSelectedMap ? (
              <></>
            ) : (
              <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                <InputLabel
                  sx={{
                    textAlign: 'left',
                  }}>
                  {capitalize(t('map'))}
                </InputLabel>
                <Controller
                  control={control}
                  name="map"
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <Select
                        options={mapsOptions}
                        onChange={async (e) => {
                          getMap({
                            variables: { id: e.value },
                          });
                          onChange(e);
                        }}
                        value={value ? value : ''}
                        name={name}
                        placeholder={t('selectField', { field: t('map') }) as string}
                      />
                    );
                  }}
                />
              </Grid>
            )}

            {(selectedMap || preSelectedMap) && (
              <>
                <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                  <Controller
                    control={control}
                    name="center"
                    render={({ field: { onChange, value } }) => {
                      const currentMap = getSelectedMap();
                      const centerLat = (currentMap.pointNeGeo.latitude + currentMap.pointSwGeo.latitude) / 2;
                      const centerLng = (currentMap.pointNeGeo.longitude + currentMap.pointSwGeo.longitude) / 2;
                      return (
                        <LocationPicker
                          value={value || { lat: centerLat, lng: centerLng }}
                          setValue={(value: any) => onChange(value)}
                          error={Boolean(errors.center)}
                          label={capitalize(t('feature'))}
                          initialZoom={currentMap.initialZoom}
                          roads={mapData?.maps[0].roads}
                          features={mapData?.maps[0].features}
                          customTile={currentMap.tileUrl}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={8} xs={12} sx={{ width: '100%' }}>
                  <Controller
                    control={control}
                    name="fromZoom"
                    render={({ field: { onChange, value } }) => {
                      const dict: any = {
                        0: 'Baja',
                        1: 'Media Baja',
                        2: 'Media',
                        3: 'Media Alta',
                        4: 'Alta',
                      };
                      const marks = Object.entries(dict).map(([v, k]) => {
                        return { value: Number(v), label: k };
                      });

                      const currentMap = getSelectedMap();
                      const differenceZoom = (currentMap.maxZoom - currentMap.minZoom) / (Object.keys(dict).length - 1);
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 1, alignItems: 'center' }}>
                          <Box sx={{ flex: '1 1 auto', minWidth: '5rem', mb: 1 }}>
                            <Typography gutterBottom>{capitalize(t('priority'))}</Typography>
                          </Box>
                          <Box sx={{ flex: '1 1 auto', minWidth: '10rem', maxWidth: '40rem', mb: 1 }}>
                            <Typography variant="body2">{t('priorityDescription')}</Typography>
                          </Box>
                          <Box sx={{ flex: '1 1 100%', minWidth: '15rem', maxWidth: '40rem', m: 'auto' }}>
                            <Slider
                              value={(currentMap.maxZoom - value) / differenceZoom}
                              defaultValue={marks.length - 1}
                              step={1}
                              min={0}
                              max={marks.length - 1}
                              marks={marks}
                              onChange={(e) => {
                                const eventTarget: any = e.target;
                                const fromZoom = currentMap.maxZoom - differenceZoom * eventTarget.value;
                                onChange(fromZoom);
                              }}
                            />
                          </Box>
                        </Box>
                      );
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="fromZoom"
                    render={({ message }) => <FormHelperText error={Boolean(message)}>{message}</FormHelperText>}
                  />
                </Grid>
                <Grid item md={8} xs={12} sx={{ width: '100%' }}>
                  <Controller
                    control={control}
                    name="toZoom"
                    render={({ field: { onChange, value } }) => {
                      const dict: any = {
                        0: 'Baja',
                        1: 'Media Baja',
                        2: 'Media',
                        3: 'Media Alta',
                        4: 'Alta',
                      };
                      const marks = Object.entries(dict).map(([v, k]) => {
                        return { value: Number(v), label: k };
                      });

                      const currentMap = getSelectedMap();
                      const differenceZoom = (currentMap.maxZoom - currentMap.minZoom) / (Object.keys(dict).length - 1);
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 1, alignItems: 'center' }}>
                          <Box sx={{ flex: '1 1 auto', minWidth: '5rem', mb: 1 }}>
                            <Typography gutterBottom>{capitalize(t('toZoom'))}</Typography>
                          </Box>
                          <Box sx={{ flex: '1 1 auto', minWidth: '10rem', maxWidth: '40rem', mb: 1 }}>
                            <Typography variant="body2">{t('toZoomDescription')}</Typography>
                          </Box>
                          <Box sx={{ flex: '1 1 100%', minWidth: '15rem', maxWidth: '40rem', m: 'auto' }}>
                            <Slider
                              value={(currentMap.maxZoom - (value || currentMap.maxZoom)) / differenceZoom}
                              defaultValue={0}
                              step={1}
                              min={0}
                              max={marks.length - 1}
                              marks={marks}
                              onChange={(e) => {
                                const eventTarget: any = e.target;
                                const toZoom = currentMap.maxZoom - differenceZoom * eventTarget.value;
                                onChange(toZoom);
                              }}
                            />
                          </Box>
                        </Box>
                      );
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="toZoom"
                    render={({ message }) => <FormHelperText error={Boolean(message)}>{message}</FormHelperText>}
                  />
                </Grid>
                <Grid container mt={5} spacing={1} direction="row">
                  <Grid item xs={4}>
                    <ErrorMessage
                      errors={errors}
                      name="nodes"
                      render={({ message }) => <FormHelperText error={Boolean(message)}>{message}</FormHelperText>}
                    />
                    <Controller
                      control={control}
                      name="nodes"
                      render={({ field: { onChange, value } }) => (
                        <NodesEdit map={getSelectedMap()} onChange={onChange} nodes={value} marker={center} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name="color"
                      render={({ field: { onChange, value } }) => (
                        <>
                          <TextField
                            fullWidth
                            label={`Color del contorno del pin ${canUpdateLite ? '' : ' (solo planes de pago)'}`}
                            type="text"
                            variant="outlined"
                            value={value}
                            disabled={!canUpdateLite}
                            error={Boolean(errors.color)}
                            helperText={errors.color?.message || ' '}
                            onClick={() => setOpen(true)}
                          />
                          <Dialog onClose={() => setOpen(false)} open={open}>
                            <SketchPicker color={value} onChange={(e: any) => onChange(e.hex)} />
                          </Dialog>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item md={8} xs={12} sx={{ width: '100%' }}>
              <InputLabel
                sx={{
                  textAlign: 'left',
                }}>
                {`${capitalize(t('images'))} ${canUpdateLite ? '' : '(multiples imágenes solo con planes de pago)'}`}
              </InputLabel>
              <Controller
                control={control}
                name="photoUrls"
                render={({ field: { onChange, value } }) => (
                  <ImageDropzone setFiles={onChange} files={value} allowedFileTypes={[]} multi={canUpdateLite} />
                )}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ width: '100%' }}>
              <InputLabel
                sx={{
                  textAlign: 'left',
                }}>{`${capitalize(t('pin'))} ${canUpdateLite ? '(optional)' : '(solo planes de pago)'}`}</InputLabel>
              <Controller
                control={control}
                name="pin"
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <Select
                      isClearable={true}
                      options={pinsOptions}
                      onChange={onChange}
                      value={value || ''}
                      name={name}
                      isDisabled={!canUpdateLite}
                      placeholder={t('selectField', { field: t('pin') }) as string}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <div>
            <Button
              sx={{
                margin: 1,
                position: 'relative',
              }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}>
              {t('submit')}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12,
                }}
              />
            )}
          </div>
        </CardActions>
      </Card>
    </form>
  );
};
export default FeatureForm;

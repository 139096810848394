import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601DateTime: any;
  JSON: any;
};

export type AskPermissionMutationPayload = {
  __typename?: 'AskPermissionMutationPayload';
  permissions?: Maybe<Array<PermissionResponse>>;
};

export type BestRoadPayload = {
  __typename?: 'BestRoadPayload';
  path?: Maybe<Array<Scalars['ID']>>;
  distance?: Maybe<Scalars['Float']>;
  expectedTime?: Maybe<Scalars['Float']>;
};

export type ColorPalette = {
  __typename?: 'ColorPalette';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  place?: Maybe<Place>;
  placeId?: Maybe<Scalars['ID']>;
  target?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Contact = {
  __typename?: 'Contact';
  createdAt: Scalars['ISO8601DateTime'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  mobile?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  place?: Maybe<Place>;
  tikTok?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  web?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
};

export type CreateColorPaletteMutationPayload = {
  __typename?: 'CreateColorPaletteMutationPayload';
  colorPalette?: Maybe<ColorPalette>;
};

export type CreateContactMutationPayload = {
  __typename?: 'CreateContactMutationPayload';
  contact?: Maybe<Contact>;
};

export type CreateEntranceMutationPayload = {
  __typename?: 'CreateEntranceMutationPayload';
  entrance?: Maybe<Entrance>;
};

export type CreateEventCategoryMutationPayload = {
  __typename?: 'CreateEventCategoryMutationPayload';
  eventCategory?: Maybe<EventCategory>;
};

export type CreateEventMutationPayload = {
  __typename?: 'CreateEventMutationPayload';
  event?: Maybe<Event>;
};

export type CreateFeatureMutationPayload = {
  __typename?: 'CreateFeatureMutationPayload';
  feature?: Maybe<Feature>;
};

export type CreateGcpFileMutationPayload = {
  __typename?: 'CreateGcpFileMutationPayload';
  gcpFile?: Maybe<GcpFile>;
};

export type CreateLocationCategoryMutationPayload = {
  __typename?: 'CreateLocationCategoryMutationPayload';
  locationCategory?: Maybe<LocationCategory>;
};

export type CreateLocationCategoryRelationMutationPayload = {
  __typename?: 'CreateLocationCategoryRelationMutationPayload';
  location?: Maybe<Location>;
  locationCategory?: Maybe<LocationCategory>;
};

export type CreateLocationMutationPayload = {
  __typename?: 'CreateLocationMutationPayload';
  location?: Maybe<Location>;
};

export type CreateMapMutationPayload = {
  __typename?: 'CreateMapMutationPayload';
  map?: Maybe<Map>;
};

export type CreateNodeMutationPayload = {
  __typename?: 'CreateNodeMutationPayload';
  node?: Maybe<Node>;
};

export type CreateNotificationMutationPayload = {
  __typename?: 'CreateNotificationMutationPayload';
  notification?: Maybe<Notification>;
};

export type CreatePinMutationPayload = {
  __typename?: 'CreatePinMutationPayload';
  pin?: Maybe<Pin>;
};

export type CreatePlaceMutationPayload = {
  __typename?: 'CreatePlaceMutationPayload';
  place?: Maybe<Place>;
};

export type CreatePlaceRolesMutationPayload = {
  __typename?: 'CreatePlaceRolesMutationPayload';
  roles?: Maybe<Array<Role>>;
};

export type CreateReviewMutationPayload = {
  __typename?: 'CreateReviewMutationPayload';
  review?: Maybe<Review>;
};

export type CreateRoadFromFileMutationPayload = {
  __typename?: 'CreateRoadFromFileMutationPayload';
  nodes?: Maybe<Array<Node>>;
};

export type CreateRoadMutationPayload = {
  __typename?: 'CreateRoadMutationPayload';
  road?: Maybe<Road>;
};

export type CreateScheduleMutationPayload = {
  __typename?: 'CreateScheduleMutationPayload';
  schedule?: Maybe<Schedule>;
};

export type CreateUserMutationPayload = {
  __typename?: 'CreateUserMutationPayload';
  user?: Maybe<Profile>;
};

export type DeleteColorPaletteMutationPayload = {
  __typename?: 'DeleteColorPaletteMutationPayload';
  success: Scalars['String'];
};

export type DeleteContactMutationPayload = {
  __typename?: 'DeleteContactMutationPayload';
  success: Scalars['String'];
};

export type DeleteEntranceMutationPayload = {
  __typename?: 'DeleteEntranceMutationPayload';
  success: Scalars['String'];
};

export type DeleteEventCategoryMutationPayload = {
  __typename?: 'DeleteEventCategoryMutationPayload';
  success: Scalars['String'];
};

export type DeleteEventMutationPayload = {
  __typename?: 'DeleteEventMutationPayload';
  deletedFiles?: Maybe<Array<Scalars['String']>>;
  success: Scalars['String'];
};

export type DeleteFeatureMutationPayload = {
  __typename?: 'DeleteFeatureMutationPayload';
  deletedFiles?: Maybe<Array<Scalars['String']>>;
  success: Scalars['String'];
};

export type DeleteLocationCategoryMutationPayload = {
  __typename?: 'DeleteLocationCategoryMutationPayload';
  success: Scalars['String'];
};

export type DeleteLocationCategoryRelationMutationPayload = {
  __typename?: 'DeleteLocationCategoryRelationMutationPayload';
  success: Scalars['String'];
};

export type DeleteLocationMutationPayload = {
  __typename?: 'DeleteLocationMutationPayload';
  deletedFiles?: Maybe<Array<Scalars['String']>>;
  success: Scalars['String'];
};

export type DeleteMapMutationPayload = {
  __typename?: 'DeleteMapMutationPayload';
  success: Scalars['String'];
};

export type DeleteNodeMutationPayload = {
  __typename?: 'DeleteNodeMutationPayload';
  success: Scalars['String'];
};

export type DeleteNotificationMutationPayload = {
  __typename?: 'DeleteNotificationMutationPayload';
  success: Scalars['String'];
};

export type DeletePinMutationPayload = {
  __typename?: 'DeletePinMutationPayload';
  deletedFiles?: Maybe<Array<Scalars['String']>>;
  success: Scalars['String'];
};

export type DeletePlaceMutationPayload = {
  __typename?: 'DeletePlaceMutationPayload';
  success: Scalars['String'];
};

export type DeleteReviewMutationPayload = {
  __typename?: 'DeleteReviewMutationPayload';
  success: Scalars['String'];
};

export type DeleteRoadMutationPayload = {
  __typename?: 'DeleteRoadMutationPayload';
  success: Scalars['String'];
};

export type DeleteScheduleMutationPayload = {
  __typename?: 'DeleteScheduleMutationPayload';
  success: Scalars['String'];
};

export type DeleteUserMutationPayload = {
  __typename?: 'DeleteUserMutationPayload';
  success: Scalars['String'];
};

export type Entrance = {
  __typename?: 'Entrance';
  createdAt: Scalars['ISO8601DateTime'];
  feature?: Maybe<Feature>;
  featureId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  pointGeo?: Maybe<Point>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Event = {
  __typename?: 'Event';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  discountedPrice?: Maybe<Scalars['Int']>;
  eventCategory?: Maybe<EventCategory>;
  eventCategoryId?: Maybe<Scalars['ID']>;
  expiration?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type EventCategory = {
  __typename?: 'EventCategory';
  createdAt: Scalars['ISO8601DateTime'];
  events?: Maybe<Array<Event>>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ExportPlaceMutationPayload = {
  __typename?: 'ExportPlaceMutationPayload';
  data?: Maybe<Scalars['String']>;
};

export type Feature = {
  __typename?: 'Feature';
  centerGeo?: Maybe<Point>;
  closestNode?: Maybe<Node>;
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  entrances?: Maybe<Array<Entrance>>;
  fromZoom?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['ID']>;
  map?: Maybe<Map>;
  mapId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  nodes?: Maybe<Array<Node>>;
  photoUrls?: Maybe<Array<Scalars['String']>>;
  pin?: Maybe<Pin>;
  pinId?: Maybe<Scalars['ID']>;
  toZoom?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type File = {
  __typename?: 'File';
  url?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
};

export type GcpFile = {
  __typename?: 'GcpFile';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  objectId?: Maybe<Scalars['ID']>;
  objectType?: Maybe<Scalars['String']>;
  place?: Maybe<Place>;
  placeId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['ISO8601DateTime'];
  url: Scalars['String'];
};

export type HardDeletePlaceMutationPayload = {
  __typename?: 'HardDeletePlaceMutationPayload';
  deletedFiles?: Maybe<Array<Scalars['String']>>;
  success: Scalars['String'];
};


export type ImportPlaceMutationPayload = {
  __typename?: 'ImportPlaceMutationPayload';
  id: Scalars['ID'];
};


export type Location = {
  __typename?: 'Location';
  canReview?: Maybe<Scalars['Boolean']>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['ISO8601DateTime'];
  dynamicDescription?: Maybe<Scalars['JSON']>;
  eventCategories?: Maybe<Array<EventCategory>>;
  events?: Maybe<Array<Event>>;
  features?: Maybe<Array<Feature>>;
  id: Scalars['ID'];
  infographics?: Maybe<Scalars['JSON']>;
  locationCategories?: Maybe<Array<LocationCategory>>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  place?: Maybe<Place>;
  placeId?: Maybe<Scalars['ID']>;
  rating?: Maybe<Scalars['Float']>;
  reviews?: Maybe<Array<Review>>;
  schedules?: Maybe<Array<Schedule>>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type LocationCategory = {
  __typename?: 'LocationCategory';
  createdAt: Scalars['ISO8601DateTime'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locations?: Maybe<Array<Location>>;
  name?: Maybe<Scalars['String']>;
  place?: Maybe<Place>;
  placeId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Login = {
  __typename?: 'Login';
  email: Scalars['String'];
  expiresIn: Scalars['String'];
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
  uid: Scalars['String'];
  registered?: Maybe<Scalars['Boolean']>;
};

export type LoginMutationPayload = {
  __typename?: 'LoginMutationPayload';
  login?: Maybe<Login>;
};

export type Map = {
  __typename?: 'Map';
  createdAt: Scalars['ISO8601DateTime'];
  features?: Maybe<Array<Feature>>;
  floor?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  initialZoom?: Maybe<Scalars['Float']>;
  maxZoom?: Maybe<Scalars['Float']>;
  minZoom?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  place?: Maybe<Place>;
  placeId?: Maybe<Scalars['ID']>;
  pointNeGeo?: Maybe<Point>;
  pointSwGeo?: Maybe<Point>;
  roads?: Maybe<Array<Road>>;
  tileUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Me = {
  __typename?: 'Me';
  id: Scalars['ID'];
  uid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  createdAt: Scalars['ISO8601DateTime'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createColorPalette: CreateColorPaletteMutationPayload;
  createContact: CreateContactMutationPayload;
  createEntrance: CreateEntranceMutationPayload;
  createEvent: CreateEventMutationPayload;
  createEventCategory: CreateEventCategoryMutationPayload;
  createFeature: CreateFeatureMutationPayload;
  createLocation: CreateLocationMutationPayload;
  createLocationCategory: CreateLocationCategoryMutationPayload;
  createLocationCategoryRelation: CreateLocationCategoryRelationMutationPayload;
  createMap: CreateMapMutationPayload;
  createNode: CreateNodeMutationPayload;
  createNotification: CreateNotificationMutationPayload;
  createPin: CreatePinMutationPayload;
  createPlace: CreatePlaceMutationPayload;
  createReview: CreateReviewMutationPayload;
  createRoad: CreateRoadMutationPayload;
  createRoadFromFile: CreateRoadFromFileMutationPayload;
  createSchedule: CreateScheduleMutationPayload;
  deleteColorPalette: DeleteColorPaletteMutationPayload;
  deleteContact: DeleteContactMutationPayload;
  deleteEntrance: DeleteEntranceMutationPayload;
  deleteEvent: DeleteEventMutationPayload;
  deleteEventCategory: DeleteEventCategoryMutationPayload;
  deleteFeature: DeleteFeatureMutationPayload;
  deleteLocation: DeleteLocationMutationPayload;
  deleteLocationCategory: DeleteLocationCategoryMutationPayload;
  deleteLocationCategoryRelation: DeleteLocationCategoryRelationMutationPayload;
  deleteMap: DeleteMapMutationPayload;
  deleteNode: DeleteNodeMutationPayload;
  deleteNotification: DeleteNotificationMutationPayload;
  deletePin: DeletePinMutationPayload;
  deletePlace: DeletePlaceMutationPayload;
  deleteReview: DeleteReviewMutationPayload;
  deleteRoad: DeleteRoadMutationPayload;
  deleteSchedule: DeleteScheduleMutationPayload;
  exportPlace: ExportPlaceMutationPayload;
  hardDeletePlace: HardDeletePlaceMutationPayload;
  importPlace: ImportPlaceMutationPayload;
  restorePlace: RestorePlaceMutationPayload;
  updateColorPalette: UpdateColorPaletteMutationPayload;
  updateContact: UpdateContactMutationPayload;
  updateEntrance: UpdateEntranceMutationPayload;
  updateEvent: UpdateEventMutationPayload;
  updateEventCategory: UpdateEventCategoryMutationPayload;
  updateFeature: UpdateFeatureMutationPayload;
  updateFeatureNodes: UpdateFeatureNodesMutationPayload;
  updateLocation: UpdateLocationMutationPayload;
  updateLocationCategory: UpdateLocationCategoryMutationPayload;
  updateMap: UpdateMapMutationPayload;
  updateNode: UpdateNodeMutationPayload;
  updateNotification: UpdateNotificationMutationPayload;
  updatePin: UpdatePinMutationPayload;
  updatePlace: UpdatePlaceMutationPayload;
  updateReview: UpdateReviewMutationPayload;
  updateRoad: UpdateRoadMutationPayload;
  updateRoadNodes: UpdateRoadNodesMutationPayload;
  updateSchedule: UpdateScheduleMutationPayload;
  deleteUser: DeleteUserMutationPayload;
  updateRole: UpdateRoleMutationPayload;
  updateUser: UpdateUserMutationPayload;
  uploadFile: UploadFileMutationPayload;
  login: LoginMutationPayload;
  refreshToken: RefreshTokenMutationPayload;
  register: RegisterMutationPayload;
};


export type MutationCreateColorPaletteArgs = {
  target: Scalars['String'];
  color: Scalars['String'];
  placeId: Scalars['ID'];
};


export type MutationCreateContactArgs = {
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  tikTok?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  placeId: Scalars['ID'];
};


export type MutationCreateEntranceArgs = {
  name?: Maybe<Scalars['String']>;
  point: Array<Scalars['Float']>;
  featureId: Scalars['ID'];
};


export type MutationCreateEventArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  discountedPrice?: Maybe<Scalars['Int']>;
  expiration: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  eventCategoryId: Scalars['ID'];
  locationId: Scalars['ID'];
};


export type MutationCreateEventCategoryArgs = {
  name: Scalars['String'];
  locationId: Scalars['ID'];
};


export type MutationCreateFeatureArgs = {
  name: Scalars['String'];
  center: Array<Scalars['Float']>;
  photoUrls?: Maybe<Array<Scalars['String']>>;
  fromZoom?: Maybe<Scalars['Float']>;
  toZoom?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  locationId: Scalars['ID'];
  mapId: Scalars['ID'];
  pinId?: Maybe<Scalars['ID']>;
};


export type MutationCreateLocationArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dynamicDescription?: Maybe<Scalars['JSON']>;
  infographics?: Maybe<Scalars['JSON']>;
  logoUrl?: Maybe<Scalars['String']>;
  canReview?: Maybe<Scalars['Boolean']>;
  contactId?: Maybe<Scalars['ID']>;
  placeId: Scalars['ID'];
  locationCategoriesIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateLocationCategoryArgs = {
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  placeId: Scalars['ID'];
  locationsIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateLocationCategoryRelationArgs = {
  locationId: Scalars['ID'];
  locationCategoryId: Scalars['ID'];
};


export type MutationCreateMapArgs = {
  floor: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  pointNe: Array<Scalars['Float']>;
  pointSw: Array<Scalars['Float']>;
  tileUrl?: Maybe<Scalars['String']>;
  initialZoom: Scalars['Float'];
  minZoom: Scalars['Float'];
  maxZoom: Scalars['Float'];
  placeId: Scalars['ID'];
};


export type MutationCreateNodeArgs = {
  point: Array<Scalars['Float']>;
  roadId?: Maybe<Scalars['ID']>;
  featureId?: Maybe<Scalars['ID']>;
};


export type MutationCreateNotificationArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  placeId: Scalars['ID'];
};


export type MutationCreatePinArgs = {
  name: Scalars['String'];
  url: Scalars['String'];
  placeId: Scalars['ID'];
};


export type MutationCreatePlaceArgs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  placeType: Scalars['String'];
  walkSpeed?: Maybe<Scalars['Float']>;
  contactId?: Maybe<Scalars['ID']>;
};


export type MutationCreateReviewArgs = {
  description?: Maybe<Scalars['String']>;
  visitDuration?: Maybe<Scalars['Int']>;
  stars: Scalars['Int'];
  visitDateTime?: Maybe<Scalars['String']>;
  locationId: Scalars['ID'];
};


export type MutationCreateRoadArgs = {
  name: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  visible?: Maybe<Scalars['Boolean']>;
  unidirectional?: Maybe<Scalars['Boolean']>;
  mapId: Scalars['ID'];
};


export type MutationCreateRoadFromFileArgs = {
  gpxFile: Scalars['String'];
  unidirectional?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  color: Scalars['String'];
  width: Scalars['Int'];
  mapId: Scalars['ID'];
};


export type MutationCreateScheduleArgs = {
  dayOfTheWeek?: Maybe<Scalars['String']>;
  specificDay?: Maybe<Scalars['String']>;
  timeRange: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  locationId: Scalars['ID'];
};


export type MutationDeleteColorPaletteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContactArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEntranceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEventCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFeatureArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLocationCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLocationCategoryRelationArgs = {
  locationId: Scalars['ID'];
  locationCategoryId: Scalars['ID'];
};


export type MutationDeleteMapArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNodeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePinArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePlaceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReviewArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRoadArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteScheduleArgs = {
  id: Scalars['ID'];
};


export type MutationExportPlaceArgs = {
  id: Scalars['ID'];
};


export type MutationHardDeletePlaceArgs = {
  id: Scalars['ID'];
};


export type MutationImportPlaceArgs = {
  data: Scalars['JSON'];
};


export type MutationRestorePlaceArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateColorPaletteArgs = {
  id: Scalars['ID'];
  target?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateContactArgs = {
  id: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  tikTok?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
};


export type MutationUpdateEntranceArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  point?: Maybe<Array<Scalars['Float']>>;
  featureId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateEventArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  discountedPrice?: Maybe<Scalars['Int']>;
  expiration?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  eventCategoryId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateEventCategoryArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateFeatureArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  center?: Maybe<Array<Scalars['Float']>>;
  photoUrls?: Maybe<Array<Scalars['String']>>;
  fromZoom?: Maybe<Scalars['Float']>;
  toZoom?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  mapId?: Maybe<Scalars['ID']>;
  pinId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateFeatureNodesArgs = {
  featureId: Scalars['ID'];
  nodes: Array<Array<Scalars['Float']>>;
};


export type MutationUpdateLocationArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dynamicDescription?: Maybe<Scalars['JSON']>;
  infographics?: Maybe<Scalars['JSON']>;
  logoUrl?: Maybe<Scalars['String']>;
  canReview?: Maybe<Scalars['Boolean']>;
  placeId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  locationCategoriesIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationUpdateLocationCategoryArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  locationsIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationUpdateMapArgs = {
  id: Scalars['ID'];
  floor?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pointNe?: Maybe<Array<Scalars['Float']>>;
  pointSw?: Maybe<Array<Scalars['Float']>>;
  tileUrl?: Maybe<Scalars['String']>;
  initialZoom?: Maybe<Scalars['Float']>;
  minZoom?: Maybe<Scalars['Float']>;
  maxZoom?: Maybe<Scalars['Float']>;
  placeId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateNodeArgs = {
  id: Scalars['ID'];
  point?: Maybe<Array<Scalars['Float']>>;
  roadId?: Maybe<Scalars['ID']>;
  featureId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateNotificationArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  placeId?: Maybe<Scalars['ID']>;
};


export type MutationUpdatePinArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
};


export type MutationUpdatePlaceArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  placeType?: Maybe<Scalars['String']>;
  walkSpeed?: Maybe<Scalars['Float']>;
  contactId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateReviewArgs = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  visitDuration?: Maybe<Scalars['Int']>;
  stars?: Maybe<Scalars['Int']>;
  visitDateTime?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateRoadArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  visible?: Maybe<Scalars['Boolean']>;
  unidirectional?: Maybe<Scalars['Boolean']>;
  mapId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateRoadNodesArgs = {
  roadId: Scalars['ID'];
  nodes: Array<Array<Scalars['Float']>>;
  unidirectional?: Maybe<Scalars['Boolean']>;
  testIntermediateNodes?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateScheduleArgs = {
  id: Scalars['ID'];
  dayOfTheWeek?: Maybe<Scalars['String']>;
  specificDay?: Maybe<Scalars['String']>;
  timeRange?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteUserArgs = {
  uid?: Maybe<Scalars['String']>;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['ID'];
  placeId?: Maybe<Scalars['ID']>;
  permissions?: Maybe<Array<Scalars['String']>>;
};


export type MutationUpdateUserArgs = {
  uid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['ID']>>;
};


export type MutationUploadFileArgs = {
  data: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRegisterArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Node = {
  __typename?: 'Node';
  createdAt: Scalars['ISO8601DateTime'];
  featureId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  pointGeo?: Maybe<Point>;
  road?: Maybe<Road>;
  roadId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Notification = {
  __typename?: 'Notification';
  active?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  place?: Maybe<Place>;
  placeId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PermissionResponse = {
  __typename?: 'PermissionResponse';
  permission: Scalars['String'];
  placeIds?: Maybe<Array<Scalars['String']>>;
};

export type Pin = {
  __typename?: 'Pin';
  createdAt: Scalars['ISO8601DateTime'];
  features?: Maybe<Array<Feature>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  place?: Maybe<Place>;
  placeId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['ISO8601DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type Place = {
  __typename?: 'Place';
  centerGeo?: Maybe<Point>;
  colorPalettes?: Maybe<Array<ColorPalette>>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['ID']>;
  contacts?: Maybe<Array<Contact>>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locationCategories?: Maybe<Array<LocationCategory>>;
  locations?: Maybe<Array<Location>>;
  logoUrl?: Maybe<Scalars['String']>;
  maps?: Maybe<Array<Map>>;
  name: Scalars['String'];
  notifications?: Maybe<Array<Notification>>;
  pins?: Maybe<Array<Pin>>;
  placeType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  walkSpeed?: Maybe<Scalars['Float']>;
};

export type Point = {
  __typename?: 'Point';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type Profile = {
  __typename?: 'Profile';
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  uid: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Query = {
  __typename?: 'Query';
  closestNode?: Maybe<Node>;
  colorPalettes: Array<ColorPalette>;
  contacts: Array<Contact>;
  entrances: Array<Entrance>;
  eventCategories: Array<EventCategory>;
  events: Array<Event>;
  features: Array<Feature>;
  locationCategories: Array<LocationCategory>;
  locations: Array<Location>;
  maps: Array<Map>;
  nodes: Array<Node>;
  notifications: Array<Notification>;
  pins: Array<Pin>;
  places: Array<Place>;
  relations: Array<Relation>;
  reviews: Array<Review>;
  roads: Array<Road>;
  schedules: Array<Schedule>;
  roles: Array<Role>;
  users: Array<Profile>;
  me?: Maybe<Me>;
  bestRoad?: Maybe<BestRoadPayload>;
  lastVersion?: Maybe<Version>;
};


export type QueryClosestNodeArgs = {
  currentPosition: Array<Scalars['Float']>;
  mapId: Scalars['ID'];
};


export type QueryColorPalettesArgs = {
  id?: Maybe<Scalars['ID']>;
  target?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryContactsArgs = {
  id?: Maybe<Scalars['ID']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  tikTok?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryEntrancesArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  point?: Maybe<Array<Scalars['Float']>>;
  featureId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  sortDistance?: Maybe<Array<Scalars['Float']>>;
};


export type QueryEventCategoriesArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryEventsArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  discountedPrice?: Maybe<Scalars['Int']>;
  expiration?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  eventCategoryId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryFeaturesArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  center?: Maybe<Array<Scalars['Float']>>;
  currentZoom?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['ID']>;
  mapId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  sortDistance?: Maybe<Array<Scalars['Float']>>;
};


export type QueryLocationCategoriesArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryLocationsArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dynamicDescription?: Maybe<Scalars['JSON']>;
  logoUrl?: Maybe<Scalars['String']>;
  canReview?: Maybe<Scalars['Boolean']>;
  locationCategoryId?: Maybe<Scalars['ID']>;
  placeId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryMapsArgs = {
  id?: Maybe<Scalars['ID']>;
  floor?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pointNe?: Maybe<Array<Scalars['Float']>>;
  pointSw?: Maybe<Array<Scalars['Float']>>;
  tileUrl?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryNodesArgs = {
  id?: Maybe<Scalars['ID']>;
  point?: Maybe<Array<Scalars['Float']>>;
  roadId?: Maybe<Scalars['ID']>;
  featureId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryNotificationsArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  placeId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryPinsArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryPlacesArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  placeType?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  sortDistance?: Maybe<Array<Scalars['Float']>>;
};


export type QueryRelationsArgs = {
  id?: Maybe<Scalars['ID']>;
  unidirectional?: Maybe<Scalars['Boolean']>;
  placeId?: Maybe<Scalars['ID']>;
  fromAdmin?: Maybe<Scalars['Boolean']>;
};


export type QueryReviewsArgs = {
  id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  visitDuration?: Maybe<Scalars['Int']>;
  stars?: Maybe<Scalars['Int']>;
  visitDateTime?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryRoadsArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  visible?: Maybe<Scalars['Boolean']>;
  unidirectional?: Maybe<Scalars['Boolean']>;
  mapId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QuerySchedulesArgs = {
  id?: Maybe<Scalars['ID']>;
  dayOfTheWeek?: Maybe<Scalars['String']>;
  specificDay?: Maybe<Scalars['String']>;
  timeRange?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryRolesArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  uid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryBestRoadArgs = {
  currentPosition: Array<Scalars['Float']>;
  currentMapId: Scalars['ID'];
  targetNodeId: Scalars['ID'];
};

export type RefreshToken = {
  __typename?: 'RefreshToken';
  expiresIn: Scalars['String'];
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
  tokenType: Scalars['String'];
  uid: Scalars['String'];
};

export type RefreshTokenMutationPayload = {
  __typename?: 'RefreshTokenMutationPayload';
  refreshToken?: Maybe<RefreshToken>;
};

export type RegisterMutationPayload = {
  __typename?: 'RegisterMutationPayload';
  registerUser?: Maybe<RegisterUser>;
};

export type RegisterUser = {
  __typename?: 'RegisterUser';
  email: Scalars['String'];
  expiresIn: Scalars['String'];
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
  uid: Scalars['String'];
};

export type Relation = {
  __typename?: 'Relation';
  createdAt: Scalars['ISO8601DateTime'];
  distance?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  node1Id: Scalars['ID'];
  node2Id: Scalars['ID'];
  unidirectional?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type RestorePlaceMutationPayload = {
  __typename?: 'RestorePlaceMutationPayload';
  place?: Maybe<Place>;
};

export type Review = {
  __typename?: 'Review';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['ID']>;
  stars?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
  userId?: Maybe<Scalars['ID']>;
  visitDateTime?: Maybe<Scalars['ISO8601DateTime']>;
  visitDuration?: Maybe<Scalars['Int']>;
};

export type Road = {
  __typename?: 'Road';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  distance?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  map?: Maybe<Map>;
  mapId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nodes?: Maybe<Array<Node>>;
  relations?: Maybe<Array<Relation>>;
  unidirectional?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['ISO8601DateTime'];
  visible?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
};

export type Role = {
  __typename?: 'Role';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  permissions: Array<Scalars['String']>;
  placeId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['ISO8601DateTime'];
  users: Array<Profile>;
};

export type Schedule = {
  __typename?: 'Schedule';
  active?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['ISO8601DateTime'];
  dayOfTheWeek?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['ID']>;
  specificDay?: Maybe<Scalars['String']>;
  timeRange?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type UpdateColorPaletteMutationPayload = {
  __typename?: 'UpdateColorPaletteMutationPayload';
  colorPalette?: Maybe<ColorPalette>;
};

export type UpdateContactMutationPayload = {
  __typename?: 'UpdateContactMutationPayload';
  contact?: Maybe<Contact>;
};

export type UpdateEntranceMutationPayload = {
  __typename?: 'UpdateEntranceMutationPayload';
  entrance?: Maybe<Entrance>;
};

export type UpdateEventCategoryMutationPayload = {
  __typename?: 'UpdateEventCategoryMutationPayload';
  eventCategory?: Maybe<EventCategory>;
};

export type UpdateEventMutationPayload = {
  __typename?: 'UpdateEventMutationPayload';
  deletedFiles?: Maybe<Array<Scalars['String']>>;
  event?: Maybe<Event>;
};

export type UpdateFeatureMutationPayload = {
  __typename?: 'UpdateFeatureMutationPayload';
  deletedFiles?: Maybe<Array<Scalars['String']>>;
  feature?: Maybe<Feature>;
};

export type UpdateFeatureNodesMutationPayload = {
  __typename?: 'UpdateFeatureNodesMutationPayload';
  nodes?: Maybe<Array<Node>>;
};

export type UpdateLocationCategoryMutationPayload = {
  __typename?: 'UpdateLocationCategoryMutationPayload';
  locationCategory?: Maybe<LocationCategory>;
};

export type UpdateLocationMutationPayload = {
  __typename?: 'UpdateLocationMutationPayload';
  deletedFiles?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<Location>;
};

export type UpdateMapMutationPayload = {
  __typename?: 'UpdateMapMutationPayload';
  map?: Maybe<Map>;
};

export type UpdateNodeMutationPayload = {
  __typename?: 'UpdateNodeMutationPayload';
  node?: Maybe<Node>;
};

export type UpdateNotificationMutationPayload = {
  __typename?: 'UpdateNotificationMutationPayload';
  notification?: Maybe<Notification>;
};

export type UpdatePinMutationPayload = {
  __typename?: 'UpdatePinMutationPayload';
  deletedFiles?: Maybe<Array<Scalars['String']>>;
  pin?: Maybe<Pin>;
};

export type UpdatePlaceMutationPayload = {
  __typename?: 'UpdatePlaceMutationPayload';
  deletedFiles?: Maybe<Array<Scalars['String']>>;
  place?: Maybe<Place>;
};

export type UpdateReviewMutationPayload = {
  __typename?: 'UpdateReviewMutationPayload';
  review?: Maybe<Review>;
};

export type UpdateRoadMutationPayload = {
  __typename?: 'UpdateRoadMutationPayload';
  road?: Maybe<Road>;
};

export type UpdateRoadNodesMutationPayload = {
  __typename?: 'UpdateRoadNodesMutationPayload';
  nodes?: Maybe<Array<Node>>;
};

export type UpdateRoleMutationPayload = {
  __typename?: 'UpdateRoleMutationPayload';
  role?: Maybe<Role>;
};

export type UpdateScheduleMutationPayload = {
  __typename?: 'UpdateScheduleMutationPayload';
  schedule?: Maybe<Schedule>;
};

export type UpdateUserMutationPayload = {
  __typename?: 'UpdateUserMutationPayload';
  user?: Maybe<Profile>;
};

export type UploadFileMutationPayload = {
  __typename?: 'UploadFileMutationPayload';
  file?: Maybe<File>;
};

export type Version = {
  __typename?: 'Version';
  version: Scalars['String'];
  date: Scalars['String'];
  required: Scalars['Boolean'];
};

export type UploadFileMutationVariables = Exact<{
  data: Scalars['String'];
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile: (
    { __typename?: 'UploadFileMutationPayload' }
    & { file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'url' | 'filename'>
    )> }
  ) }
);

export type GetContactsQueryVariables = Exact<{
  placeId?: Maybe<Scalars['ID']>;
}>;


export type GetContactsQuery = (
  { __typename?: 'Query' }
  & { contacts: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'displayName' | 'email' | 'phone' | 'mobile' | 'whatsapp' | 'web' | 'facebook' | 'instagram' | 'tikTok' | 'linkedin' | 'twitter' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetAllContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllContactsQuery = (
  { __typename?: 'Query' }
  & { contacts: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'displayName' | 'email' | 'phone' | 'mobile' | 'whatsapp' | 'web' | 'facebook' | 'instagram' | 'tikTok' | 'linkedin' | 'twitter' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteContact: (
    { __typename?: 'DeleteContactMutationPayload' }
    & Pick<DeleteContactMutationPayload, 'success'>
  ) }
);

export type CreateContactMutationVariables = Exact<{
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  tikTok?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  placeId: Scalars['ID'];
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact: (
    { __typename?: 'CreateContactMutationPayload' }
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'displayName' | 'email' | 'phone' | 'mobile' | 'whatsapp' | 'web' | 'facebook' | 'instagram' | 'tikTok' | 'linkedin' | 'twitter' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type GetContactQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetContactQuery = (
  { __typename?: 'Query' }
  & { contacts: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'displayName' | 'email' | 'phone' | 'mobile' | 'whatsapp' | 'web' | 'facebook' | 'instagram' | 'tikTok' | 'linkedin' | 'twitter' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetContactNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetContactNameQuery = (
  { __typename?: 'Query' }
  & { contacts: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'displayName'>
  )> }
);

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  tikTok?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact: (
    { __typename?: 'UpdateContactMutationPayload' }
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'displayName' | 'email' | 'phone' | 'mobile' | 'whatsapp' | 'web' | 'facebook' | 'instagram' | 'tikTok' | 'linkedin' | 'twitter' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type GetFeaturesQueryVariables = Exact<{
  mapId?: Maybe<Scalars['ID']>;
  locationId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
}>;


export type GetFeaturesQuery = (
  { __typename?: 'Query' }
  & { features: Array<(
    { __typename?: 'Feature' }
    & Pick<Feature, 'id' | 'name' | 'fromZoom' | 'toZoom' | 'createdAt' | 'updatedAt'>
    & { centerGeo?: Maybe<(
      { __typename?: 'Point' }
      & Pick<Point, 'latitude' | 'longitude'>
    )>, location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'name'>
    )>, map?: Maybe<(
      { __typename?: 'Map' }
      & Pick<Map, 'id' | 'name' | 'floor' | 'createdAt' | 'updatedAt' | 'tileUrl'>
    )> }
  )> }
);

export type DeleteFeatureMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteFeatureMutation = (
  { __typename?: 'Mutation' }
  & { deleteFeature: (
    { __typename?: 'DeleteFeatureMutationPayload' }
    & Pick<DeleteFeatureMutationPayload, 'success'>
  ) }
);

export type CreateFeatureMutationVariables = Exact<{
  locationId: Scalars['ID'];
  mapId: Scalars['ID'];
  name: Scalars['String'];
  fromZoom?: Maybe<Scalars['Float']>;
  toZoom?: Maybe<Scalars['Float']>;
  center: Array<Scalars['Float']> | Scalars['Float'];
  color?: Maybe<Scalars['String']>;
  pinId?: Maybe<Scalars['ID']>;
}>;


export type CreateFeatureMutation = (
  { __typename?: 'Mutation' }
  & { createFeature: (
    { __typename?: 'CreateFeatureMutationPayload' }
    & { feature?: Maybe<(
      { __typename?: 'Feature' }
      & Pick<Feature, 'id'>
    )> }
  ) }
);

export type GetFeatureQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetFeatureQuery = (
  { __typename?: 'Query' }
  & { features: Array<(
    { __typename?: 'Feature' }
    & Pick<Feature, 'id' | 'name' | 'color' | 'fromZoom' | 'toZoom' | 'photoUrls' | 'createdAt' | 'updatedAt'>
    & { pin?: Maybe<(
      { __typename?: 'Pin' }
      & Pick<Pin, 'id' | 'name' | 'url'>
    )>, centerGeo?: Maybe<(
      { __typename?: 'Point' }
      & Pick<Point, 'latitude' | 'longitude'>
    )>, map?: Maybe<(
      { __typename?: 'Map' }
      & Pick<Map, 'id' | 'name' | 'floor' | 'createdAt' | 'updatedAt' | 'tileUrl'>
      & { pointNeGeo?: Maybe<(
        { __typename?: 'Point' }
        & Pick<Point, 'latitude' | 'longitude'>
      )>, pointSwGeo?: Maybe<(
        { __typename?: 'Point' }
        & Pick<Point, 'latitude' | 'longitude'>
      )>, features?: Maybe<Array<(
        { __typename?: 'Feature' }
        & Pick<Feature, 'name'>
        & { location?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'name'>
        )>, centerGeo?: Maybe<(
          { __typename?: 'Point' }
          & Pick<Point, 'latitude' | 'longitude'>
        )>, entrances?: Maybe<Array<(
          { __typename?: 'Entrance' }
          & Pick<Entrance, 'name'>
          & { pointGeo?: Maybe<(
            { __typename?: 'Point' }
            & Pick<Point, 'latitude' | 'longitude'>
          )> }
        )>> }
      )>> }
    )>, nodes?: Maybe<Array<(
      { __typename?: 'Node' }
      & Pick<Node, 'id'>
      & { pointGeo?: Maybe<(
        { __typename?: 'Point' }
        & Pick<Point, 'latitude' | 'longitude'>
      )> }
    )>> }
  )> }
);

export type GetFeatureNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetFeatureNameQuery = (
  { __typename?: 'Query' }
  & { features: Array<(
    { __typename?: 'Feature' }
    & Pick<Feature, 'name'>
  )> }
);

export type UpdateFeatureMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  fromZoom?: Maybe<Scalars['Float']>;
  toZoom?: Maybe<Scalars['Float']>;
  center: Array<Scalars['Float']> | Scalars['Float'];
  pinId?: Maybe<Scalars['ID']>;
  mapId?: Maybe<Scalars['ID']>;
  photoUrls?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  color?: Maybe<Scalars['String']>;
}>;


export type UpdateFeatureMutation = (
  { __typename?: 'Mutation' }
  & { updateFeature: (
    { __typename?: 'UpdateFeatureMutationPayload' }
    & { feature?: Maybe<(
      { __typename?: 'Feature' }
      & Pick<Feature, 'id'>
    )> }
  ) }
);

export type UpdateFeatureNodesMutationVariables = Exact<{
  featureId: Scalars['ID'];
  nodes: Array<Array<Scalars['Float']> | Scalars['Float']> | Array<Scalars['Float']> | Scalars['Float'];
}>;


export type UpdateFeatureNodesMutation = (
  { __typename?: 'Mutation' }
  & { updateFeatureNodes: (
    { __typename?: 'UpdateFeatureNodesMutationPayload' }
    & { nodes?: Maybe<Array<(
      { __typename?: 'Node' }
      & Pick<Node, 'id'>
    )>> }
  ) }
);

export type GetLocationCategoriesQueryVariables = Exact<{
  id: Scalars['ID'];
  search?: Maybe<Scalars['String']>;
}>;


export type GetLocationCategoriesQuery = (
  { __typename?: 'Query' }
  & { locationCategories: Array<(
    { __typename?: 'LocationCategory' }
    & Pick<LocationCategory, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeleteLocationCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLocationCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteLocationCategory: (
    { __typename?: 'DeleteLocationCategoryMutationPayload' }
    & Pick<DeleteLocationCategoryMutationPayload, 'success'>
  ) }
);

export type CreateLocationCategoryMutationVariables = Exact<{
  placeId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type CreateLocationCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createLocationCategory: (
    { __typename?: 'CreateLocationCategoryMutationPayload' }
    & { locationCategory?: Maybe<(
      { __typename?: 'LocationCategory' }
      & Pick<LocationCategory, 'id' | 'name'>
    )> }
  ) }
);

export type GetLocationCategoryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLocationCategoryQuery = (
  { __typename?: 'Query' }
  & { locationCategories: Array<(
    { __typename?: 'LocationCategory' }
    & Pick<LocationCategory, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetLocationCategoryNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLocationCategoryNameQuery = (
  { __typename?: 'Query' }
  & { locationCategories: Array<(
    { __typename?: 'LocationCategory' }
    & Pick<LocationCategory, 'name'>
  )> }
);

export type UpdatelocationCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdatelocationCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateLocationCategory: (
    { __typename?: 'UpdateLocationCategoryMutationPayload' }
    & { locationCategory?: Maybe<(
      { __typename?: 'LocationCategory' }
      & Pick<LocationCategory, 'id'>
    )> }
  ) }
);

export type GetLocationsQueryVariables = Exact<{
  id: Scalars['ID'];
  search?: Maybe<Scalars['String']>;
}>;


export type GetLocationsQuery = (
  { __typename?: 'Query' }
  & { locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'dynamicDescription' | 'infographics' | 'canReview' | 'createdAt' | 'updatedAt' | 'logoUrl'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'displayName' | 'createdAt' | 'updatedAt'>
    )> }
  )> }
);

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & { deleteLocation: (
    { __typename?: 'DeleteLocationMutationPayload' }
    & Pick<DeleteLocationMutationPayload, 'success'>
  ) }
);

export type CreateLocationMutationVariables = Exact<{
  placeId: Scalars['ID'];
  name: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  dynamicDescription?: Maybe<Scalars['JSON']>;
  infographics?: Maybe<Scalars['JSON']>;
  contactId?: Maybe<Scalars['ID']>;
  locationCategoriesIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreateLocationMutation = (
  { __typename?: 'Mutation' }
  & { createLocation: (
    { __typename?: 'CreateLocationMutationPayload' }
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )> }
  ) }
);

export type GetLocationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLocationQuery = (
  { __typename?: 'Query' }
  & { locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'canReview' | 'createdAt' | 'updatedAt' | 'logoUrl' | 'contactId' | 'dynamicDescription' | 'infographics'>
    & { locationCategories?: Maybe<Array<(
      { __typename?: 'LocationCategory' }
      & Pick<LocationCategory, 'id' | 'name'>
    )>> }
  )> }
);

export type GetLocationNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLocationNameQuery = (
  { __typename?: 'Query' }
  & { locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'name'>
  )> }
);

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  canReview?: Maybe<Scalars['Boolean']>;
  dynamicDescription?: Maybe<Scalars['JSON']>;
  infographics?: Maybe<Scalars['JSON']>;
  contactId?: Maybe<Scalars['ID']>;
  locationCategoriesIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocation: (
    { __typename?: 'UpdateLocationMutationPayload' }
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'id'>
    )> }
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginMutationPayload' }
    & { login?: Maybe<(
      { __typename?: 'Login' }
      & Pick<Login, 'email' | 'expiresIn' | 'idToken' | 'refreshToken' | 'registered' | 'uid'>
    )> }
  ) }
);

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { refreshToken: (
    { __typename?: 'RefreshTokenMutationPayload' }
    & { refreshToken?: Maybe<(
      { __typename?: 'RefreshToken' }
      & Pick<RefreshToken, 'expiresIn' | 'idToken' | 'refreshToken' | 'uid'>
    )> }
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Me' }
    & Pick<Me, 'id' | 'uid' | 'email' | 'name' | 'lastName' | 'gender' | 'photoUrl' | 'phone'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'name' | 'placeId' | 'permissions'>
    )> }
  )> }
);

export type GetMapsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMapsQuery = (
  { __typename?: 'Query' }
  & { places: Array<(
    { __typename?: 'Place' }
    & Pick<Place, 'id'>
    & { maps?: Maybe<Array<(
      { __typename?: 'Map' }
      & Pick<Map, 'id' | 'name' | 'floor' | 'createdAt' | 'updatedAt' | 'tileUrl' | 'initialZoom' | 'minZoom' | 'maxZoom'>
      & { pointNeGeo?: Maybe<(
        { __typename?: 'Point' }
        & Pick<Point, 'latitude' | 'longitude'>
      )>, pointSwGeo?: Maybe<(
        { __typename?: 'Point' }
        & Pick<Point, 'latitude' | 'longitude'>
      )> }
    )>> }
  )> }
);

export type DeleteMapMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMapMutation = (
  { __typename?: 'Mutation' }
  & { deleteMap: (
    { __typename?: 'DeleteMapMutationPayload' }
    & Pick<DeleteMapMutationPayload, 'success'>
  ) }
);

export type CreateMapMutationVariables = Exact<{
  placeId: Scalars['ID'];
  name: Scalars['String'];
  floor: Scalars['Int'];
  tileUrl?: Maybe<Scalars['String']>;
  pointNe: Array<Scalars['Float']> | Scalars['Float'];
  pointSw: Array<Scalars['Float']> | Scalars['Float'];
  initialZoom: Scalars['Float'];
  minZoom: Scalars['Float'];
  maxZoom: Scalars['Float'];
}>;


export type CreateMapMutation = (
  { __typename?: 'Mutation' }
  & { createMap: (
    { __typename?: 'CreateMapMutationPayload' }
    & { map?: Maybe<(
      { __typename?: 'Map' }
      & Pick<Map, 'id'>
    )> }
  ) }
);

export type GetMapQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMapQuery = (
  { __typename?: 'Query' }
  & { maps: Array<(
    { __typename?: 'Map' }
    & Pick<Map, 'id' | 'name' | 'floor' | 'createdAt' | 'updatedAt' | 'tileUrl' | 'initialZoom' | 'minZoom' | 'maxZoom'>
    & { pointNeGeo?: Maybe<(
      { __typename?: 'Point' }
      & Pick<Point, 'latitude' | 'longitude'>
    )>, pointSwGeo?: Maybe<(
      { __typename?: 'Point' }
      & Pick<Point, 'latitude' | 'longitude'>
    )>, roads?: Maybe<Array<(
      { __typename?: 'Road' }
      & Pick<Road, 'id' | 'name' | 'color' | 'width' | 'visible' | 'unidirectional' | 'createdAt' | 'updatedAt'>
      & { nodes?: Maybe<Array<(
        { __typename?: 'Node' }
        & Pick<Node, 'id'>
        & { pointGeo?: Maybe<(
          { __typename?: 'Point' }
          & Pick<Point, 'latitude' | 'longitude'>
        )> }
      )>> }
    )>>, features?: Maybe<Array<(
      { __typename?: 'Feature' }
      & Pick<Feature, 'id' | 'name' | 'fromZoom' | 'toZoom' | 'createdAt' | 'updatedAt'>
      & { location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'name'>
      )>, centerGeo?: Maybe<(
        { __typename?: 'Point' }
        & Pick<Point, 'latitude' | 'longitude'>
      )>, entrances?: Maybe<Array<(
        { __typename?: 'Entrance' }
        & Pick<Entrance, 'name'>
        & { pointGeo?: Maybe<(
          { __typename?: 'Point' }
          & Pick<Point, 'latitude' | 'longitude'>
        )> }
      )>> }
    )>> }
  )> }
);

export type GetMapZoomsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMapZoomsQuery = (
  { __typename?: 'Query' }
  & { maps: Array<(
    { __typename?: 'Map' }
    & Pick<Map, 'initialZoom' | 'minZoom' | 'maxZoom'>
  )> }
);

export type GetMapNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMapNameQuery = (
  { __typename?: 'Query' }
  & { maps: Array<(
    { __typename?: 'Map' }
    & Pick<Map, 'name'>
  )> }
);

export type UpdateMapMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  floor: Scalars['Int'];
  tileUrl?: Maybe<Scalars['String']>;
  pointNe: Array<Scalars['Float']> | Scalars['Float'];
  pointSw: Array<Scalars['Float']> | Scalars['Float'];
  initialZoom: Scalars['Float'];
  minZoom: Scalars['Float'];
  maxZoom: Scalars['Float'];
}>;


export type UpdateMapMutation = (
  { __typename?: 'Mutation' }
  & { updateMap: (
    { __typename?: 'UpdateMapMutationPayload' }
    & { map?: Maybe<(
      { __typename?: 'Map' }
      & Pick<Map, 'id'>
    )> }
  ) }
);

export type GetNotificationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { places: Array<(
    { __typename?: 'Place' }
    & Pick<Place, 'id'>
    & { notifications?: Maybe<Array<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id' | 'name' | 'description' | 'active' | 'createdAt' | 'updatedAt'>
    )>> }
  )> }
);

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteNotification: (
    { __typename?: 'DeleteNotificationMutationPayload' }
    & Pick<DeleteNotificationMutationPayload, 'success'>
  ) }
);

export type CreateNotificationMutationVariables = Exact<{
  placeId: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  active: Scalars['Boolean'];
}>;


export type CreateNotificationMutation = (
  { __typename?: 'Mutation' }
  & { createNotification: (
    { __typename?: 'CreateNotificationMutationPayload' }
    & { notification?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id'>
    )> }
  ) }
);

export type GetNotificationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetNotificationQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'name' | 'description' | 'active' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetNotificationNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetNotificationNameQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'name'>
  )> }
);

export type UpdateNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  active: Scalars['Boolean'];
}>;


export type UpdateNotificationMutation = (
  { __typename?: 'Mutation' }
  & { updateNotification: (
    { __typename?: 'UpdateNotificationMutationPayload' }
    & { notification?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id'>
    )> }
  ) }
);

export type GetPinsQueryVariables = Exact<{
  placeId: Scalars['ID'];
}>;


export type GetPinsQuery = (
  { __typename?: 'Query' }
  & { pins: Array<(
    { __typename?: 'Pin' }
    & Pick<Pin, 'id' | 'name' | 'url' | 'createdAt' | 'updatedAt'>
  )> }
);

export type DeletePinMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePinMutation = (
  { __typename?: 'Mutation' }
  & { deletePin: (
    { __typename?: 'DeletePinMutationPayload' }
    & Pick<DeletePinMutationPayload, 'success'>
  ) }
);

export type CreatePinMutationVariables = Exact<{
  placeId: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
}>;


export type CreatePinMutation = (
  { __typename?: 'Mutation' }
  & { createPin: (
    { __typename?: 'CreatePinMutationPayload' }
    & { pin?: Maybe<(
      { __typename?: 'Pin' }
      & Pick<Pin, 'id'>
    )> }
  ) }
);

export type GetPinQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPinQuery = (
  { __typename?: 'Query' }
  & { pins: Array<(
    { __typename?: 'Pin' }
    & Pick<Pin, 'id' | 'name' | 'url' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetPinNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPinNameQuery = (
  { __typename?: 'Query' }
  & { pins: Array<(
    { __typename?: 'Pin' }
    & Pick<Pin, 'name'>
  )> }
);

export type UpdatePinMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
}>;


export type UpdatePinMutation = (
  { __typename?: 'Mutation' }
  & { updatePin: (
    { __typename?: 'UpdatePinMutationPayload' }
    & { pin?: Maybe<(
      { __typename?: 'Pin' }
      & Pick<Pin, 'id'>
    )> }
  ) }
);

export type GetPlacesQueryVariables = Exact<{
  inactive?: Maybe<Scalars['Boolean']>;
}>;


export type GetPlacesQuery = (
  { __typename?: 'Query' }
  & { places: Array<(
    { __typename?: 'Place' }
    & Pick<Place, 'id' | 'name' | 'description' | 'walkSpeed' | 'placeType' | 'createdAt' | 'updatedAt'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'displayName'>
    )> }
  )> }
);

export type DeletePlaceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePlaceMutation = (
  { __typename?: 'Mutation' }
  & { deletePlace: (
    { __typename?: 'DeletePlaceMutationPayload' }
    & Pick<DeletePlaceMutationPayload, 'success'>
  ) }
);

export type HardDeletePlaceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type HardDeletePlaceMutation = (
  { __typename?: 'Mutation' }
  & { hardDeletePlace: (
    { __typename?: 'HardDeletePlaceMutationPayload' }
    & Pick<HardDeletePlaceMutationPayload, 'success'>
  ) }
);

export type RestorePlaceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RestorePlaceMutation = (
  { __typename?: 'Mutation' }
  & { restorePlace: (
    { __typename?: 'RestorePlaceMutationPayload' }
    & { place?: Maybe<(
      { __typename?: 'Place' }
      & Pick<Place, 'id'>
    )> }
  ) }
);

export type CreatePlaceMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  placeType: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  walkSpeed?: Maybe<Scalars['Float']>;
  contactId?: Maybe<Scalars['ID']>;
}>;


export type CreatePlaceMutation = (
  { __typename?: 'Mutation' }
  & { createPlace: (
    { __typename?: 'CreatePlaceMutationPayload' }
    & { place?: Maybe<(
      { __typename?: 'Place' }
      & Pick<Place, 'id'>
    )> }
  ) }
);

export type GetPlaceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPlaceQuery = (
  { __typename?: 'Query' }
  & { places: Array<(
    { __typename?: 'Place' }
    & Pick<Place, 'id' | 'name' | 'description' | 'walkSpeed' | 'placeType' | 'createdAt' | 'updatedAt' | 'logoUrl' | 'contactId'>
    & { pins?: Maybe<Array<(
      { __typename?: 'Pin' }
      & Pick<Pin, 'id'>
    )>> }
  )> }
);

export type GetPlaceNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPlaceNameQuery = (
  { __typename?: 'Query' }
  & { places: Array<(
    { __typename?: 'Place' }
    & Pick<Place, 'name'>
  )> }
);

export type UpdatePlaceMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  placeType: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  walkSpeed?: Maybe<Scalars['Float']>;
  contactId?: Maybe<Scalars['ID']>;
}>;


export type UpdatePlaceMutation = (
  { __typename?: 'Mutation' }
  & { updatePlace: (
    { __typename?: 'UpdatePlaceMutationPayload' }
    & { place?: Maybe<(
      { __typename?: 'Place' }
      & Pick<Place, 'id'>
    )> }
  ) }
);

export type ExportPlaceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ExportPlaceMutation = (
  { __typename?: 'Mutation' }
  & { exportPlace: (
    { __typename?: 'ExportPlaceMutationPayload' }
    & Pick<ExportPlaceMutationPayload, 'data'>
  ) }
);

export type ImportPlaceMutationVariables = Exact<{
  data: Scalars['JSON'];
}>;


export type ImportPlaceMutation = (
  { __typename?: 'Mutation' }
  & { importPlace: (
    { __typename?: 'ImportPlaceMutationPayload' }
    & Pick<ImportPlaceMutationPayload, 'id'>
  ) }
);

export type GetRoadsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRoadsQuery = (
  { __typename?: 'Query' }
  & { maps: Array<(
    { __typename?: 'Map' }
    & { roads?: Maybe<Array<(
      { __typename?: 'Road' }
      & Pick<Road, 'id' | 'name' | 'color' | 'width' | 'visible' | 'unidirectional' | 'distance' | 'createdAt' | 'updatedAt'>
      & { nodes?: Maybe<Array<(
        { __typename?: 'Node' }
        & Pick<Node, 'id'>
        & { pointGeo?: Maybe<(
          { __typename?: 'Point' }
          & Pick<Point, 'latitude' | 'longitude'>
        )> }
      )>> }
    )>> }
  )> }
);

export type GetRoadNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRoadNameQuery = (
  { __typename?: 'Query' }
  & { roads: Array<(
    { __typename?: 'Road' }
    & Pick<Road, 'name'>
  )> }
);

export type DeleteRoadMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRoadMutation = (
  { __typename?: 'Mutation' }
  & { deleteRoad: (
    { __typename?: 'DeleteRoadMutationPayload' }
    & Pick<DeleteRoadMutationPayload, 'success'>
  ) }
);

export type CreateRoadMutationVariables = Exact<{
  mapId: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
  width: Scalars['Int'];
  visible?: Maybe<Scalars['Boolean']>;
  unidirectional?: Maybe<Scalars['Boolean']>;
}>;


export type CreateRoadMutation = (
  { __typename?: 'Mutation' }
  & { createRoad: (
    { __typename?: 'CreateRoadMutationPayload' }
    & { road?: Maybe<(
      { __typename?: 'Road' }
      & Pick<Road, 'id'>
    )> }
  ) }
);

export type UpdateRoadMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  visible?: Maybe<Scalars['Boolean']>;
  unidirectional?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateRoadMutation = (
  { __typename?: 'Mutation' }
  & { updateRoad: (
    { __typename?: 'UpdateRoadMutationPayload' }
    & { road?: Maybe<(
      { __typename?: 'Road' }
      & Pick<Road, 'id'>
    )> }
  ) }
);

export type UpdateRoadNodesMutationVariables = Exact<{
  roadId: Scalars['ID'];
  nodes: Array<Array<Scalars['Float']> | Scalars['Float']> | Array<Scalars['Float']> | Scalars['Float'];
  unidirectional: Scalars['Boolean'];
  testIntermediateNodes: Scalars['Boolean'];
}>;


export type UpdateRoadNodesMutation = (
  { __typename?: 'Mutation' }
  & { updateRoadNodes: (
    { __typename?: 'UpdateRoadNodesMutationPayload' }
    & { nodes?: Maybe<Array<(
      { __typename?: 'Node' }
      & Pick<Node, 'id'>
    )>> }
  ) }
);

export type CreateRoadFromFileMutationVariables = Exact<{
  mapId: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
  width: Scalars['Int'];
  unidirectional: Scalars['Boolean'];
  gpxFile: Scalars['String'];
}>;


export type CreateRoadFromFileMutation = (
  { __typename?: 'Mutation' }
  & { createRoadFromFile: (
    { __typename?: 'CreateRoadFromFileMutationPayload' }
    & { nodes?: Maybe<Array<(
      { __typename?: 'Node' }
      & Pick<Node, 'id'>
    )>> }
  ) }
);

export type GetRolesQueryVariables = Exact<{
  placeId: Scalars['ID'];
}>;


export type GetRolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetAllRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'placeId'>
  )> }
);

export type GetRoleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRoleQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name' | 'permissions'>
  )> }
);

export type GetRoleNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetRoleNameQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'name'>
  )> }
);

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  permissions: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateRole: (
    { __typename?: 'UpdateRoleMutationPayload' }
    & { role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id'>
    )> }
  ) }
);

export type GetSchedulesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSchedulesQuery = (
  { __typename?: 'Query' }
  & { locations: Array<(
    { __typename?: 'Location' }
    & { schedules?: Maybe<Array<(
      { __typename?: 'Schedule' }
      & Pick<Schedule, 'id' | 'timeRange' | 'dayOfTheWeek' | 'specificDay' | 'active' | 'createdAt' | 'updatedAt'>
    )>> }
  )> }
);

export type DeleteScheduleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteScheduleMutation = (
  { __typename?: 'Mutation' }
  & { deleteSchedule: (
    { __typename?: 'DeleteScheduleMutationPayload' }
    & Pick<DeleteScheduleMutationPayload, 'success'>
  ) }
);

export type CreateScheduleMutationVariables = Exact<{
  dayOfTheWeek?: Maybe<Scalars['String']>;
  specificDay?: Maybe<Scalars['String']>;
  timeRange: Scalars['String'];
  active: Scalars['Boolean'];
  locationId: Scalars['ID'];
}>;


export type CreateScheduleMutation = (
  { __typename?: 'Mutation' }
  & { createSchedule: (
    { __typename?: 'CreateScheduleMutationPayload' }
    & { schedule?: Maybe<(
      { __typename?: 'Schedule' }
      & Pick<Schedule, 'id'>
    )> }
  ) }
);

export type GetScheduleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetScheduleQuery = (
  { __typename?: 'Query' }
  & { schedules: Array<(
    { __typename?: 'Schedule' }
    & Pick<Schedule, 'id' | 'timeRange' | 'dayOfTheWeek' | 'specificDay' | 'active' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetScheduleDayQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetScheduleDayQuery = (
  { __typename?: 'Query' }
  & { schedules: Array<(
    { __typename?: 'Schedule' }
    & Pick<Schedule, 'dayOfTheWeek'>
  )> }
);

export type UpdateScheduleMutationVariables = Exact<{
  id: Scalars['ID'];
  dayOfTheWeek?: Maybe<Scalars['String']>;
  specificDay?: Maybe<Scalars['String']>;
  timeRange: Scalars['String'];
  active: Scalars['Boolean'];
  locationId: Scalars['ID'];
}>;


export type UpdateScheduleMutation = (
  { __typename?: 'Mutation' }
  & { updateSchedule: (
    { __typename?: 'UpdateScheduleMutationPayload' }
    & { schedule?: Maybe<(
      { __typename?: 'Schedule' }
      & Pick<Schedule, 'id'>
    )> }
  ) }
);

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'uid' | 'email' | 'name' | 'lastName' | 'createdAt' | 'updatedAt'>
  )> }
);

export type GetUserQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'uid' | 'email' | 'phone' | 'name' | 'lastName' | 'gender' | 'createdAt' | 'updatedAt'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name' | 'placeId'>
    )> }
  )> }
);

export type GetUserEmailQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type GetUserEmailQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'email'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  uid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UpdateUserMutationPayload' }
    & { user?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'uid'>
    )> }
  ) }
);

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { register: (
    { __typename?: 'RegisterMutationPayload' }
    & { registerUser?: Maybe<(
      { __typename?: 'RegisterUser' }
      & Pick<RegisterUser, 'uid'>
    )> }
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  uid: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'DeleteUserMutationPayload' }
    & Pick<DeleteUserMutationPayload, 'success'>
  ) }
);


export const UploadFileDocument = gql`
    mutation uploadFile($data: String!) {
  uploadFile(data: $data) {
    file {
      url
      filename
    }
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const GetContactsDocument = gql`
    query getContacts($placeId: ID) {
  contacts(placeId: $placeId) {
    id
    displayName
    email
    phone
    mobile
    whatsapp
    web
    facebook
    instagram
    tikTok
    linkedin
    twitter
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
      }
export function useGetContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
        }
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = Apollo.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export function refetchGetContactsQuery(variables?: GetContactsQueryVariables) {
      return { query: GetContactsDocument, variables: variables }
    }
export const GetAllContactsDocument = gql`
    query getAllContacts {
  contacts {
    id
    displayName
    email
    phone
    mobile
    whatsapp
    web
    facebook
    instagram
    tikTok
    linkedin
    twitter
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetAllContactsQuery__
 *
 * To run a query within a React component, call `useGetAllContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllContactsQuery, GetAllContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllContactsQuery, GetAllContactsQueryVariables>(GetAllContactsDocument, options);
      }
export function useGetAllContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllContactsQuery, GetAllContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllContactsQuery, GetAllContactsQueryVariables>(GetAllContactsDocument, options);
        }
export type GetAllContactsQueryHookResult = ReturnType<typeof useGetAllContactsQuery>;
export type GetAllContactsLazyQueryHookResult = ReturnType<typeof useGetAllContactsLazyQuery>;
export type GetAllContactsQueryResult = Apollo.QueryResult<GetAllContactsQuery, GetAllContactsQueryVariables>;
export function refetchGetAllContactsQuery(variables?: GetAllContactsQueryVariables) {
      return { query: GetAllContactsDocument, variables: variables }
    }
export const DeleteContactDocument = gql`
    mutation deleteContact($id: ID!) {
  deleteContact(id: $id) {
    success
  }
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($displayName: String!, $email: String, $phone: String, $mobile: String, $whatsapp: String, $web: String, $facebook: String, $instagram: String, $tikTok: String, $linkedin: String, $twitter: String, $placeId: ID!) {
  createContact(
    displayName: $displayName
    email: $email
    phone: $phone
    mobile: $mobile
    whatsapp: $whatsapp
    web: $web
    facebook: $facebook
    instagram: $instagram
    tikTok: $tikTok
    linkedin: $linkedin
    twitter: $twitter
    placeId: $placeId
  ) {
    contact {
      id
      displayName
      email
      phone
      mobile
      whatsapp
      web
      facebook
      instagram
      tikTok
      linkedin
      twitter
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      displayName: // value for 'displayName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      mobile: // value for 'mobile'
 *      whatsapp: // value for 'whatsapp'
 *      web: // value for 'web'
 *      facebook: // value for 'facebook'
 *      instagram: // value for 'instagram'
 *      tikTok: // value for 'tikTok'
 *      linkedin: // value for 'linkedin'
 *      twitter: // value for 'twitter'
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const GetContactDocument = gql`
    query getContact($id: ID!) {
  contacts(id: $id) {
    id
    displayName
    email
    phone
    mobile
    whatsapp
    web
    facebook
    instagram
    tikTok
    linkedin
    twitter
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactQuery(baseOptions: Apollo.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
      }
export function useGetContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
        }
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = Apollo.QueryResult<GetContactQuery, GetContactQueryVariables>;
export function refetchGetContactQuery(variables?: GetContactQueryVariables) {
      return { query: GetContactDocument, variables: variables }
    }
export const GetContactNameDocument = gql`
    query getContactName($id: ID!) {
  contacts(id: $id) {
    displayName
  }
}
    `;

/**
 * __useGetContactNameQuery__
 *
 * To run a query within a React component, call `useGetContactNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactNameQuery(baseOptions: Apollo.QueryHookOptions<GetContactNameQuery, GetContactNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactNameQuery, GetContactNameQueryVariables>(GetContactNameDocument, options);
      }
export function useGetContactNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactNameQuery, GetContactNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactNameQuery, GetContactNameQueryVariables>(GetContactNameDocument, options);
        }
export type GetContactNameQueryHookResult = ReturnType<typeof useGetContactNameQuery>;
export type GetContactNameLazyQueryHookResult = ReturnType<typeof useGetContactNameLazyQuery>;
export type GetContactNameQueryResult = Apollo.QueryResult<GetContactNameQuery, GetContactNameQueryVariables>;
export function refetchGetContactNameQuery(variables?: GetContactNameQueryVariables) {
      return { query: GetContactNameDocument, variables: variables }
    }
export const UpdateContactDocument = gql`
    mutation updateContact($id: ID!, $displayName: String, $email: String, $phone: String, $mobile: String, $whatsapp: String, $web: String, $facebook: String, $instagram: String, $tikTok: String, $linkedin: String, $twitter: String) {
  updateContact(
    id: $id
    displayName: $displayName
    email: $email
    phone: $phone
    mobile: $mobile
    whatsapp: $whatsapp
    web: $web
    facebook: $facebook
    instagram: $instagram
    tikTok: $tikTok
    linkedin: $linkedin
    twitter: $twitter
  ) {
    contact {
      id
      displayName
      email
      phone
      mobile
      whatsapp
      web
      facebook
      instagram
      tikTok
      linkedin
      twitter
      createdAt
      updatedAt
    }
  }
}
    `;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayName: // value for 'displayName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      mobile: // value for 'mobile'
 *      whatsapp: // value for 'whatsapp'
 *      web: // value for 'web'
 *      facebook: // value for 'facebook'
 *      instagram: // value for 'instagram'
 *      tikTok: // value for 'tikTok'
 *      linkedin: // value for 'linkedin'
 *      twitter: // value for 'twitter'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const GetFeaturesDocument = gql`
    query getFeatures($mapId: ID, $locationId: ID, $search: String) {
  features(mapId: $mapId, locationId: $locationId, search: $search) {
    id
    name
    fromZoom
    toZoom
    centerGeo {
      latitude
      longitude
    }
    location {
      name
    }
    map {
      id
      name
      floor
      createdAt
      updatedAt
      tileUrl
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetFeaturesQuery__
 *
 * To run a query within a React component, call `useGetFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturesQuery({
 *   variables: {
 *      mapId: // value for 'mapId'
 *      locationId: // value for 'locationId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, options);
      }
export function useGetFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, options);
        }
export type GetFeaturesQueryHookResult = ReturnType<typeof useGetFeaturesQuery>;
export type GetFeaturesLazyQueryHookResult = ReturnType<typeof useGetFeaturesLazyQuery>;
export type GetFeaturesQueryResult = Apollo.QueryResult<GetFeaturesQuery, GetFeaturesQueryVariables>;
export function refetchGetFeaturesQuery(variables?: GetFeaturesQueryVariables) {
      return { query: GetFeaturesDocument, variables: variables }
    }
export const DeleteFeatureDocument = gql`
    mutation deleteFeature($id: ID!) {
  deleteFeature(id: $id) {
    success
  }
}
    `;
export type DeleteFeatureMutationFn = Apollo.MutationFunction<DeleteFeatureMutation, DeleteFeatureMutationVariables>;

/**
 * __useDeleteFeatureMutation__
 *
 * To run a mutation, you first call `useDeleteFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeatureMutation, { data, loading, error }] = useDeleteFeatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFeatureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeatureMutation, DeleteFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeatureMutation, DeleteFeatureMutationVariables>(DeleteFeatureDocument, options);
      }
export type DeleteFeatureMutationHookResult = ReturnType<typeof useDeleteFeatureMutation>;
export type DeleteFeatureMutationResult = Apollo.MutationResult<DeleteFeatureMutation>;
export type DeleteFeatureMutationOptions = Apollo.BaseMutationOptions<DeleteFeatureMutation, DeleteFeatureMutationVariables>;
export const CreateFeatureDocument = gql`
    mutation createFeature($locationId: ID!, $mapId: ID!, $name: String!, $fromZoom: Float, $toZoom: Float, $center: [Float!]!, $color: String, $pinId: ID) {
  createFeature(
    locationId: $locationId
    mapId: $mapId
    name: $name
    fromZoom: $fromZoom
    toZoom: $toZoom
    center: $center
    color: $color
    pinId: $pinId
  ) {
    feature {
      id
    }
  }
}
    `;
export type CreateFeatureMutationFn = Apollo.MutationFunction<CreateFeatureMutation, CreateFeatureMutationVariables>;

/**
 * __useCreateFeatureMutation__
 *
 * To run a mutation, you first call `useCreateFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeatureMutation, { data, loading, error }] = useCreateFeatureMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      mapId: // value for 'mapId'
 *      name: // value for 'name'
 *      fromZoom: // value for 'fromZoom'
 *      toZoom: // value for 'toZoom'
 *      center: // value for 'center'
 *      color: // value for 'color'
 *      pinId: // value for 'pinId'
 *   },
 * });
 */
export function useCreateFeatureMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeatureMutation, CreateFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeatureMutation, CreateFeatureMutationVariables>(CreateFeatureDocument, options);
      }
export type CreateFeatureMutationHookResult = ReturnType<typeof useCreateFeatureMutation>;
export type CreateFeatureMutationResult = Apollo.MutationResult<CreateFeatureMutation>;
export type CreateFeatureMutationOptions = Apollo.BaseMutationOptions<CreateFeatureMutation, CreateFeatureMutationVariables>;
export const GetFeatureDocument = gql`
    query getFeature($id: ID!) {
  features(id: $id) {
    id
    name
    color
    fromZoom
    toZoom
    pin {
      id
      name
      url
    }
    centerGeo {
      latitude
      longitude
    }
    map {
      id
      name
      floor
      createdAt
      updatedAt
      tileUrl
      pointNeGeo {
        latitude
        longitude
      }
      pointSwGeo {
        latitude
        longitude
      }
      features {
        name
        location {
          name
        }
        centerGeo {
          latitude
          longitude
        }
        entrances {
          name
          pointGeo {
            latitude
            longitude
          }
        }
      }
    }
    photoUrls
    createdAt
    updatedAt
    nodes {
      id
      pointGeo {
        latitude
        longitude
      }
    }
  }
}
    `;

/**
 * __useGetFeatureQuery__
 *
 * To run a query within a React component, call `useGetFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetFeatureQuery, GetFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureQuery, GetFeatureQueryVariables>(GetFeatureDocument, options);
      }
export function useGetFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureQuery, GetFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureQuery, GetFeatureQueryVariables>(GetFeatureDocument, options);
        }
export type GetFeatureQueryHookResult = ReturnType<typeof useGetFeatureQuery>;
export type GetFeatureLazyQueryHookResult = ReturnType<typeof useGetFeatureLazyQuery>;
export type GetFeatureQueryResult = Apollo.QueryResult<GetFeatureQuery, GetFeatureQueryVariables>;
export function refetchGetFeatureQuery(variables?: GetFeatureQueryVariables) {
      return { query: GetFeatureDocument, variables: variables }
    }
export const GetFeatureNameDocument = gql`
    query getFeatureName($id: ID!) {
  features(id: $id) {
    name
  }
}
    `;

/**
 * __useGetFeatureNameQuery__
 *
 * To run a query within a React component, call `useGetFeatureNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFeatureNameQuery(baseOptions: Apollo.QueryHookOptions<GetFeatureNameQuery, GetFeatureNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureNameQuery, GetFeatureNameQueryVariables>(GetFeatureNameDocument, options);
      }
export function useGetFeatureNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureNameQuery, GetFeatureNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureNameQuery, GetFeatureNameQueryVariables>(GetFeatureNameDocument, options);
        }
export type GetFeatureNameQueryHookResult = ReturnType<typeof useGetFeatureNameQuery>;
export type GetFeatureNameLazyQueryHookResult = ReturnType<typeof useGetFeatureNameLazyQuery>;
export type GetFeatureNameQueryResult = Apollo.QueryResult<GetFeatureNameQuery, GetFeatureNameQueryVariables>;
export function refetchGetFeatureNameQuery(variables?: GetFeatureNameQueryVariables) {
      return { query: GetFeatureNameDocument, variables: variables }
    }
export const UpdateFeatureDocument = gql`
    mutation updateFeature($id: ID!, $name: String!, $fromZoom: Float, $toZoom: Float, $center: [Float!]!, $pinId: ID, $mapId: ID, $photoUrls: [String!], $color: String) {
  updateFeature(
    id: $id
    name: $name
    center: $center
    fromZoom: $fromZoom
    toZoom: $toZoom
    pinId: $pinId
    mapId: $mapId
    photoUrls: $photoUrls
    color: $color
  ) {
    feature {
      id
    }
  }
}
    `;
export type UpdateFeatureMutationFn = Apollo.MutationFunction<UpdateFeatureMutation, UpdateFeatureMutationVariables>;

/**
 * __useUpdateFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeatureMutation, { data, loading, error }] = useUpdateFeatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      fromZoom: // value for 'fromZoom'
 *      toZoom: // value for 'toZoom'
 *      center: // value for 'center'
 *      pinId: // value for 'pinId'
 *      mapId: // value for 'mapId'
 *      photoUrls: // value for 'photoUrls'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useUpdateFeatureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeatureMutation, UpdateFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeatureMutation, UpdateFeatureMutationVariables>(UpdateFeatureDocument, options);
      }
export type UpdateFeatureMutationHookResult = ReturnType<typeof useUpdateFeatureMutation>;
export type UpdateFeatureMutationResult = Apollo.MutationResult<UpdateFeatureMutation>;
export type UpdateFeatureMutationOptions = Apollo.BaseMutationOptions<UpdateFeatureMutation, UpdateFeatureMutationVariables>;
export const UpdateFeatureNodesDocument = gql`
    mutation updateFeatureNodes($featureId: ID!, $nodes: [[Float!]!]!) {
  updateFeatureNodes(featureId: $featureId, nodes: $nodes) {
    nodes {
      id
    }
  }
}
    `;
export type UpdateFeatureNodesMutationFn = Apollo.MutationFunction<UpdateFeatureNodesMutation, UpdateFeatureNodesMutationVariables>;

/**
 * __useUpdateFeatureNodesMutation__
 *
 * To run a mutation, you first call `useUpdateFeatureNodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeatureNodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeatureNodesMutation, { data, loading, error }] = useUpdateFeatureNodesMutation({
 *   variables: {
 *      featureId: // value for 'featureId'
 *      nodes: // value for 'nodes'
 *   },
 * });
 */
export function useUpdateFeatureNodesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeatureNodesMutation, UpdateFeatureNodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeatureNodesMutation, UpdateFeatureNodesMutationVariables>(UpdateFeatureNodesDocument, options);
      }
export type UpdateFeatureNodesMutationHookResult = ReturnType<typeof useUpdateFeatureNodesMutation>;
export type UpdateFeatureNodesMutationResult = Apollo.MutationResult<UpdateFeatureNodesMutation>;
export type UpdateFeatureNodesMutationOptions = Apollo.BaseMutationOptions<UpdateFeatureNodesMutation, UpdateFeatureNodesMutationVariables>;
export const GetLocationCategoriesDocument = gql`
    query getLocationCategories($id: ID!, $search: String) {
  locationCategories(placeId: $id, search: $search) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetLocationCategoriesQuery__
 *
 * To run a query within a React component, call `useGetLocationCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetLocationCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetLocationCategoriesQuery, GetLocationCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationCategoriesQuery, GetLocationCategoriesQueryVariables>(GetLocationCategoriesDocument, options);
      }
export function useGetLocationCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationCategoriesQuery, GetLocationCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationCategoriesQuery, GetLocationCategoriesQueryVariables>(GetLocationCategoriesDocument, options);
        }
export type GetLocationCategoriesQueryHookResult = ReturnType<typeof useGetLocationCategoriesQuery>;
export type GetLocationCategoriesLazyQueryHookResult = ReturnType<typeof useGetLocationCategoriesLazyQuery>;
export type GetLocationCategoriesQueryResult = Apollo.QueryResult<GetLocationCategoriesQuery, GetLocationCategoriesQueryVariables>;
export function refetchGetLocationCategoriesQuery(variables?: GetLocationCategoriesQueryVariables) {
      return { query: GetLocationCategoriesDocument, variables: variables }
    }
export const DeleteLocationCategoryDocument = gql`
    mutation deleteLocationCategory($id: ID!) {
  deleteLocationCategory(id: $id) {
    success
  }
}
    `;
export type DeleteLocationCategoryMutationFn = Apollo.MutationFunction<DeleteLocationCategoryMutation, DeleteLocationCategoryMutationVariables>;

/**
 * __useDeleteLocationCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteLocationCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationCategoryMutation, { data, loading, error }] = useDeleteLocationCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationCategoryMutation, DeleteLocationCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationCategoryMutation, DeleteLocationCategoryMutationVariables>(DeleteLocationCategoryDocument, options);
      }
export type DeleteLocationCategoryMutationHookResult = ReturnType<typeof useDeleteLocationCategoryMutation>;
export type DeleteLocationCategoryMutationResult = Apollo.MutationResult<DeleteLocationCategoryMutation>;
export type DeleteLocationCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteLocationCategoryMutation, DeleteLocationCategoryMutationVariables>;
export const CreateLocationCategoryDocument = gql`
    mutation createLocationCategory($placeId: ID!, $name: String!) {
  createLocationCategory(placeId: $placeId, name: $name) {
    locationCategory {
      id
      name
    }
  }
}
    `;
export type CreateLocationCategoryMutationFn = Apollo.MutationFunction<CreateLocationCategoryMutation, CreateLocationCategoryMutationVariables>;

/**
 * __useCreateLocationCategoryMutation__
 *
 * To run a mutation, you first call `useCreateLocationCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationCategoryMutation, { data, loading, error }] = useCreateLocationCategoryMutation({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateLocationCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationCategoryMutation, CreateLocationCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationCategoryMutation, CreateLocationCategoryMutationVariables>(CreateLocationCategoryDocument, options);
      }
export type CreateLocationCategoryMutationHookResult = ReturnType<typeof useCreateLocationCategoryMutation>;
export type CreateLocationCategoryMutationResult = Apollo.MutationResult<CreateLocationCategoryMutation>;
export type CreateLocationCategoryMutationOptions = Apollo.BaseMutationOptions<CreateLocationCategoryMutation, CreateLocationCategoryMutationVariables>;
export const GetLocationCategoryDocument = gql`
    query getLocationCategory($id: ID!) {
  locationCategories(id: $id) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetLocationCategoryQuery__
 *
 * To run a query within a React component, call `useGetLocationCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetLocationCategoryQuery, GetLocationCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationCategoryQuery, GetLocationCategoryQueryVariables>(GetLocationCategoryDocument, options);
      }
export function useGetLocationCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationCategoryQuery, GetLocationCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationCategoryQuery, GetLocationCategoryQueryVariables>(GetLocationCategoryDocument, options);
        }
export type GetLocationCategoryQueryHookResult = ReturnType<typeof useGetLocationCategoryQuery>;
export type GetLocationCategoryLazyQueryHookResult = ReturnType<typeof useGetLocationCategoryLazyQuery>;
export type GetLocationCategoryQueryResult = Apollo.QueryResult<GetLocationCategoryQuery, GetLocationCategoryQueryVariables>;
export function refetchGetLocationCategoryQuery(variables?: GetLocationCategoryQueryVariables) {
      return { query: GetLocationCategoryDocument, variables: variables }
    }
export const GetLocationCategoryNameDocument = gql`
    query getLocationCategoryName($id: ID!) {
  locationCategories(id: $id) {
    name
  }
}
    `;

/**
 * __useGetLocationCategoryNameQuery__
 *
 * To run a query within a React component, call `useGetLocationCategoryNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationCategoryNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationCategoryNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationCategoryNameQuery(baseOptions: Apollo.QueryHookOptions<GetLocationCategoryNameQuery, GetLocationCategoryNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationCategoryNameQuery, GetLocationCategoryNameQueryVariables>(GetLocationCategoryNameDocument, options);
      }
export function useGetLocationCategoryNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationCategoryNameQuery, GetLocationCategoryNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationCategoryNameQuery, GetLocationCategoryNameQueryVariables>(GetLocationCategoryNameDocument, options);
        }
export type GetLocationCategoryNameQueryHookResult = ReturnType<typeof useGetLocationCategoryNameQuery>;
export type GetLocationCategoryNameLazyQueryHookResult = ReturnType<typeof useGetLocationCategoryNameLazyQuery>;
export type GetLocationCategoryNameQueryResult = Apollo.QueryResult<GetLocationCategoryNameQuery, GetLocationCategoryNameQueryVariables>;
export function refetchGetLocationCategoryNameQuery(variables?: GetLocationCategoryNameQueryVariables) {
      return { query: GetLocationCategoryNameDocument, variables: variables }
    }
export const UpdatelocationCategoryDocument = gql`
    mutation updatelocationCategory($id: ID!, $name: String!) {
  updateLocationCategory(id: $id, name: $name) {
    locationCategory {
      id
    }
  }
}
    `;
export type UpdatelocationCategoryMutationFn = Apollo.MutationFunction<UpdatelocationCategoryMutation, UpdatelocationCategoryMutationVariables>;

/**
 * __useUpdatelocationCategoryMutation__
 *
 * To run a mutation, you first call `useUpdatelocationCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatelocationCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatelocationCategoryMutation, { data, loading, error }] = useUpdatelocationCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdatelocationCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdatelocationCategoryMutation, UpdatelocationCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatelocationCategoryMutation, UpdatelocationCategoryMutationVariables>(UpdatelocationCategoryDocument, options);
      }
export type UpdatelocationCategoryMutationHookResult = ReturnType<typeof useUpdatelocationCategoryMutation>;
export type UpdatelocationCategoryMutationResult = Apollo.MutationResult<UpdatelocationCategoryMutation>;
export type UpdatelocationCategoryMutationOptions = Apollo.BaseMutationOptions<UpdatelocationCategoryMutation, UpdatelocationCategoryMutationVariables>;
export const GetLocationsDocument = gql`
    query getLocations($id: ID!, $search: String) {
  locations(placeId: $id, search: $search) {
    id
    name
    dynamicDescription
    infographics
    contact {
      id
      displayName
      createdAt
      updatedAt
    }
    canReview
    createdAt
    updatedAt
    logoUrl
  }
}
    `;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export function refetchGetLocationsQuery(variables?: GetLocationsQueryVariables) {
      return { query: GetLocationsDocument, variables: variables }
    }
export const DeleteLocationDocument = gql`
    mutation deleteLocation($id: ID!) {
  deleteLocation(id: $id) {
    success
  }
}
    `;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const CreateLocationDocument = gql`
    mutation createLocation($placeId: ID!, $name: String!, $logoUrl: String, $dynamicDescription: JSON, $infographics: JSON, $contactId: ID, $locationCategoriesIds: [ID!]) {
  createLocation(
    placeId: $placeId
    name: $name
    logoUrl: $logoUrl
    dynamicDescription: $dynamicDescription
    infographics: $infographics
    contactId: $contactId
    locationCategoriesIds: $locationCategoriesIds
  ) {
    location {
      id
      name
    }
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      name: // value for 'name'
 *      logoUrl: // value for 'logoUrl'
 *      dynamicDescription: // value for 'dynamicDescription'
 *      infographics: // value for 'infographics'
 *      contactId: // value for 'contactId'
 *      locationCategoriesIds: // value for 'locationCategoriesIds'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const GetLocationDocument = gql`
    query getLocation($id: ID!) {
  locations(id: $id) {
    id
    name
    canReview
    createdAt
    updatedAt
    logoUrl
    contactId
    dynamicDescription
    infographics
    locationCategories {
      id
      name
    }
  }
}
    `;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
      }
export function useGetLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<GetLocationQuery, GetLocationQueryVariables>;
export function refetchGetLocationQuery(variables?: GetLocationQueryVariables) {
      return { query: GetLocationDocument, variables: variables }
    }
export const GetLocationNameDocument = gql`
    query getLocationName($id: ID!) {
  locations(id: $id) {
    name
  }
}
    `;

/**
 * __useGetLocationNameQuery__
 *
 * To run a query within a React component, call `useGetLocationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationNameQuery(baseOptions: Apollo.QueryHookOptions<GetLocationNameQuery, GetLocationNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationNameQuery, GetLocationNameQueryVariables>(GetLocationNameDocument, options);
      }
export function useGetLocationNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationNameQuery, GetLocationNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationNameQuery, GetLocationNameQueryVariables>(GetLocationNameDocument, options);
        }
export type GetLocationNameQueryHookResult = ReturnType<typeof useGetLocationNameQuery>;
export type GetLocationNameLazyQueryHookResult = ReturnType<typeof useGetLocationNameLazyQuery>;
export type GetLocationNameQueryResult = Apollo.QueryResult<GetLocationNameQuery, GetLocationNameQueryVariables>;
export function refetchGetLocationNameQuery(variables?: GetLocationNameQueryVariables) {
      return { query: GetLocationNameDocument, variables: variables }
    }
export const UpdateLocationDocument = gql`
    mutation updateLocation($id: ID!, $name: String!, $logoUrl: String, $canReview: Boolean, $dynamicDescription: JSON, $infographics: JSON, $contactId: ID, $locationCategoriesIds: [ID!]) {
  updateLocation(
    id: $id
    name: $name
    logoUrl: $logoUrl
    canReview: $canReview
    dynamicDescription: $dynamicDescription
    infographics: $infographics
    contactId: $contactId
    locationCategoriesIds: $locationCategoriesIds
  ) {
    location {
      id
    }
  }
}
    `;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      logoUrl: // value for 'logoUrl'
 *      canReview: // value for 'canReview'
 *      dynamicDescription: // value for 'dynamicDescription'
 *      infographics: // value for 'infographics'
 *      contactId: // value for 'contactId'
 *      locationCategoriesIds: // value for 'locationCategoriesIds'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    login {
      email
      expiresIn
      idToken
      refreshToken
      registered
      uid
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    refreshToken {
      expiresIn
      idToken
      refreshToken
      uid
    }
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    uid
    email
    name
    lastName
    gender
    photoUrl
    phone
    roles {
      name
      placeId
      permissions
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export function refetchMeQuery(variables?: MeQueryVariables) {
      return { query: MeDocument, variables: variables }
    }
export const GetMapsDocument = gql`
    query getMaps($id: ID!) {
  places(id: $id) {
    id
    maps {
      id
      name
      floor
      createdAt
      updatedAt
      tileUrl
      initialZoom
      minZoom
      maxZoom
      pointNeGeo {
        latitude
        longitude
      }
      pointSwGeo {
        latitude
        longitude
      }
    }
  }
}
    `;

/**
 * __useGetMapsQuery__
 *
 * To run a query within a React component, call `useGetMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMapsQuery(baseOptions: Apollo.QueryHookOptions<GetMapsQuery, GetMapsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMapsQuery, GetMapsQueryVariables>(GetMapsDocument, options);
      }
export function useGetMapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMapsQuery, GetMapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMapsQuery, GetMapsQueryVariables>(GetMapsDocument, options);
        }
export type GetMapsQueryHookResult = ReturnType<typeof useGetMapsQuery>;
export type GetMapsLazyQueryHookResult = ReturnType<typeof useGetMapsLazyQuery>;
export type GetMapsQueryResult = Apollo.QueryResult<GetMapsQuery, GetMapsQueryVariables>;
export function refetchGetMapsQuery(variables?: GetMapsQueryVariables) {
      return { query: GetMapsDocument, variables: variables }
    }
export const DeleteMapDocument = gql`
    mutation deleteMap($id: ID!) {
  deleteMap(id: $id) {
    success
  }
}
    `;
export type DeleteMapMutationFn = Apollo.MutationFunction<DeleteMapMutation, DeleteMapMutationVariables>;

/**
 * __useDeleteMapMutation__
 *
 * To run a mutation, you first call `useDeleteMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMapMutation, { data, loading, error }] = useDeleteMapMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMapMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMapMutation, DeleteMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMapMutation, DeleteMapMutationVariables>(DeleteMapDocument, options);
      }
export type DeleteMapMutationHookResult = ReturnType<typeof useDeleteMapMutation>;
export type DeleteMapMutationResult = Apollo.MutationResult<DeleteMapMutation>;
export type DeleteMapMutationOptions = Apollo.BaseMutationOptions<DeleteMapMutation, DeleteMapMutationVariables>;
export const CreateMapDocument = gql`
    mutation createMap($placeId: ID!, $name: String!, $floor: Int!, $tileUrl: String, $pointNe: [Float!]!, $pointSw: [Float!]!, $initialZoom: Float!, $minZoom: Float!, $maxZoom: Float!) {
  createMap(
    placeId: $placeId
    name: $name
    floor: $floor
    tileUrl: $tileUrl
    pointNe: $pointNe
    pointSw: $pointSw
    initialZoom: $initialZoom
    minZoom: $minZoom
    maxZoom: $maxZoom
  ) {
    map {
      id
    }
  }
}
    `;
export type CreateMapMutationFn = Apollo.MutationFunction<CreateMapMutation, CreateMapMutationVariables>;

/**
 * __useCreateMapMutation__
 *
 * To run a mutation, you first call `useCreateMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMapMutation, { data, loading, error }] = useCreateMapMutation({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      name: // value for 'name'
 *      floor: // value for 'floor'
 *      tileUrl: // value for 'tileUrl'
 *      pointNe: // value for 'pointNe'
 *      pointSw: // value for 'pointSw'
 *      initialZoom: // value for 'initialZoom'
 *      minZoom: // value for 'minZoom'
 *      maxZoom: // value for 'maxZoom'
 *   },
 * });
 */
export function useCreateMapMutation(baseOptions?: Apollo.MutationHookOptions<CreateMapMutation, CreateMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMapMutation, CreateMapMutationVariables>(CreateMapDocument, options);
      }
export type CreateMapMutationHookResult = ReturnType<typeof useCreateMapMutation>;
export type CreateMapMutationResult = Apollo.MutationResult<CreateMapMutation>;
export type CreateMapMutationOptions = Apollo.BaseMutationOptions<CreateMapMutation, CreateMapMutationVariables>;
export const GetMapDocument = gql`
    query getMap($id: ID!) {
  maps(id: $id) {
    id
    name
    floor
    createdAt
    updatedAt
    tileUrl
    initialZoom
    minZoom
    maxZoom
    pointNeGeo {
      latitude
      longitude
    }
    pointSwGeo {
      latitude
      longitude
    }
    roads {
      id
      name
      color
      width
      visible
      unidirectional
      createdAt
      updatedAt
      nodes {
        id
        pointGeo {
          latitude
          longitude
        }
      }
    }
    features {
      id
      name
      fromZoom
      toZoom
      location {
        name
      }
      centerGeo {
        latitude
        longitude
      }
      entrances {
        name
        pointGeo {
          latitude
          longitude
        }
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetMapQuery__
 *
 * To run a query within a React component, call `useGetMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMapQuery(baseOptions: Apollo.QueryHookOptions<GetMapQuery, GetMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMapQuery, GetMapQueryVariables>(GetMapDocument, options);
      }
export function useGetMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMapQuery, GetMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMapQuery, GetMapQueryVariables>(GetMapDocument, options);
        }
export type GetMapQueryHookResult = ReturnType<typeof useGetMapQuery>;
export type GetMapLazyQueryHookResult = ReturnType<typeof useGetMapLazyQuery>;
export type GetMapQueryResult = Apollo.QueryResult<GetMapQuery, GetMapQueryVariables>;
export function refetchGetMapQuery(variables?: GetMapQueryVariables) {
      return { query: GetMapDocument, variables: variables }
    }
export const GetMapZoomsDocument = gql`
    query getMapZooms($id: ID!) {
  maps(id: $id) {
    initialZoom
    minZoom
    maxZoom
  }
}
    `;

/**
 * __useGetMapZoomsQuery__
 *
 * To run a query within a React component, call `useGetMapZoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapZoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapZoomsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMapZoomsQuery(baseOptions: Apollo.QueryHookOptions<GetMapZoomsQuery, GetMapZoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMapZoomsQuery, GetMapZoomsQueryVariables>(GetMapZoomsDocument, options);
      }
export function useGetMapZoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMapZoomsQuery, GetMapZoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMapZoomsQuery, GetMapZoomsQueryVariables>(GetMapZoomsDocument, options);
        }
export type GetMapZoomsQueryHookResult = ReturnType<typeof useGetMapZoomsQuery>;
export type GetMapZoomsLazyQueryHookResult = ReturnType<typeof useGetMapZoomsLazyQuery>;
export type GetMapZoomsQueryResult = Apollo.QueryResult<GetMapZoomsQuery, GetMapZoomsQueryVariables>;
export function refetchGetMapZoomsQuery(variables?: GetMapZoomsQueryVariables) {
      return { query: GetMapZoomsDocument, variables: variables }
    }
export const GetMapNameDocument = gql`
    query getMapName($id: ID!) {
  maps(id: $id) {
    name
  }
}
    `;

/**
 * __useGetMapNameQuery__
 *
 * To run a query within a React component, call `useGetMapNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMapNameQuery(baseOptions: Apollo.QueryHookOptions<GetMapNameQuery, GetMapNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMapNameQuery, GetMapNameQueryVariables>(GetMapNameDocument, options);
      }
export function useGetMapNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMapNameQuery, GetMapNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMapNameQuery, GetMapNameQueryVariables>(GetMapNameDocument, options);
        }
export type GetMapNameQueryHookResult = ReturnType<typeof useGetMapNameQuery>;
export type GetMapNameLazyQueryHookResult = ReturnType<typeof useGetMapNameLazyQuery>;
export type GetMapNameQueryResult = Apollo.QueryResult<GetMapNameQuery, GetMapNameQueryVariables>;
export function refetchGetMapNameQuery(variables?: GetMapNameQueryVariables) {
      return { query: GetMapNameDocument, variables: variables }
    }
export const UpdateMapDocument = gql`
    mutation updateMap($id: ID!, $name: String!, $floor: Int!, $tileUrl: String, $pointNe: [Float!]!, $pointSw: [Float!]!, $initialZoom: Float!, $minZoom: Float!, $maxZoom: Float!) {
  updateMap(
    id: $id
    name: $name
    floor: $floor
    tileUrl: $tileUrl
    pointNe: $pointNe
    pointSw: $pointSw
    initialZoom: $initialZoom
    minZoom: $minZoom
    maxZoom: $maxZoom
  ) {
    map {
      id
    }
  }
}
    `;
export type UpdateMapMutationFn = Apollo.MutationFunction<UpdateMapMutation, UpdateMapMutationVariables>;

/**
 * __useUpdateMapMutation__
 *
 * To run a mutation, you first call `useUpdateMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMapMutation, { data, loading, error }] = useUpdateMapMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      floor: // value for 'floor'
 *      tileUrl: // value for 'tileUrl'
 *      pointNe: // value for 'pointNe'
 *      pointSw: // value for 'pointSw'
 *      initialZoom: // value for 'initialZoom'
 *      minZoom: // value for 'minZoom'
 *      maxZoom: // value for 'maxZoom'
 *   },
 * });
 */
export function useUpdateMapMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMapMutation, UpdateMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMapMutation, UpdateMapMutationVariables>(UpdateMapDocument, options);
      }
export type UpdateMapMutationHookResult = ReturnType<typeof useUpdateMapMutation>;
export type UpdateMapMutationResult = Apollo.MutationResult<UpdateMapMutation>;
export type UpdateMapMutationOptions = Apollo.BaseMutationOptions<UpdateMapMutation, UpdateMapMutationVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications($id: ID!) {
  places(id: $id) {
    id
    notifications {
      id
      name
      description
      active
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export function refetchGetNotificationsQuery(variables?: GetNotificationsQueryVariables) {
      return { query: GetNotificationsDocument, variables: variables }
    }
export const DeleteNotificationDocument = gql`
    mutation deleteNotification($id: ID!) {
  deleteNotification(id: $id) {
    success
  }
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, options);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export const CreateNotificationDocument = gql`
    mutation createNotification($placeId: ID!, $name: String!, $description: String!, $active: Boolean!) {
  createNotification(
    placeId: $placeId
    name: $name
    description: $description
    active: $active
  ) {
    notification {
      id
    }
  }
}
    `;
export type CreateNotificationMutationFn = Apollo.MutationFunction<CreateNotificationMutation, CreateNotificationMutationVariables>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useCreateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationMutation, CreateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, options);
      }
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<CreateNotificationMutation, CreateNotificationMutationVariables>;
export const GetNotificationDocument = gql`
    query getNotification($id: ID!) {
  notifications(id: $id) {
    id
    name
    description
    active
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetNotificationQuery__
 *
 * To run a query within a React component, call `useGetNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotificationQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationQuery, GetNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationQuery, GetNotificationQueryVariables>(GetNotificationDocument, options);
      }
export function useGetNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationQuery, GetNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationQuery, GetNotificationQueryVariables>(GetNotificationDocument, options);
        }
export type GetNotificationQueryHookResult = ReturnType<typeof useGetNotificationQuery>;
export type GetNotificationLazyQueryHookResult = ReturnType<typeof useGetNotificationLazyQuery>;
export type GetNotificationQueryResult = Apollo.QueryResult<GetNotificationQuery, GetNotificationQueryVariables>;
export function refetchGetNotificationQuery(variables?: GetNotificationQueryVariables) {
      return { query: GetNotificationDocument, variables: variables }
    }
export const GetNotificationNameDocument = gql`
    query getNotificationName($id: ID!) {
  notifications(id: $id) {
    name
  }
}
    `;

/**
 * __useGetNotificationNameQuery__
 *
 * To run a query within a React component, call `useGetNotificationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotificationNameQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationNameQuery, GetNotificationNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationNameQuery, GetNotificationNameQueryVariables>(GetNotificationNameDocument, options);
      }
export function useGetNotificationNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationNameQuery, GetNotificationNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationNameQuery, GetNotificationNameQueryVariables>(GetNotificationNameDocument, options);
        }
export type GetNotificationNameQueryHookResult = ReturnType<typeof useGetNotificationNameQuery>;
export type GetNotificationNameLazyQueryHookResult = ReturnType<typeof useGetNotificationNameLazyQuery>;
export type GetNotificationNameQueryResult = Apollo.QueryResult<GetNotificationNameQuery, GetNotificationNameQueryVariables>;
export function refetchGetNotificationNameQuery(variables?: GetNotificationNameQueryVariables) {
      return { query: GetNotificationNameDocument, variables: variables }
    }
export const UpdateNotificationDocument = gql`
    mutation updateNotification($id: ID!, $name: String!, $description: String!, $active: Boolean!) {
  updateNotification(
    id: $id
    name: $name
    description: $description
    active: $active
  ) {
    notification {
      id
    }
  }
}
    `;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<UpdateNotificationMutation, UpdateNotificationMutationVariables>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMutation, UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
export const GetPinsDocument = gql`
    query getPins($placeId: ID!) {
  pins(placeId: $placeId) {
    id
    name
    url
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetPinsQuery__
 *
 * To run a query within a React component, call `useGetPinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPinsQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetPinsQuery(baseOptions: Apollo.QueryHookOptions<GetPinsQuery, GetPinsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPinsQuery, GetPinsQueryVariables>(GetPinsDocument, options);
      }
export function useGetPinsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPinsQuery, GetPinsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPinsQuery, GetPinsQueryVariables>(GetPinsDocument, options);
        }
export type GetPinsQueryHookResult = ReturnType<typeof useGetPinsQuery>;
export type GetPinsLazyQueryHookResult = ReturnType<typeof useGetPinsLazyQuery>;
export type GetPinsQueryResult = Apollo.QueryResult<GetPinsQuery, GetPinsQueryVariables>;
export function refetchGetPinsQuery(variables?: GetPinsQueryVariables) {
      return { query: GetPinsDocument, variables: variables }
    }
export const DeletePinDocument = gql`
    mutation deletePin($id: ID!) {
  deletePin(id: $id) {
    success
  }
}
    `;
export type DeletePinMutationFn = Apollo.MutationFunction<DeletePinMutation, DeletePinMutationVariables>;

/**
 * __useDeletePinMutation__
 *
 * To run a mutation, you first call `useDeletePinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePinMutation, { data, loading, error }] = useDeletePinMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePinMutation(baseOptions?: Apollo.MutationHookOptions<DeletePinMutation, DeletePinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePinMutation, DeletePinMutationVariables>(DeletePinDocument, options);
      }
export type DeletePinMutationHookResult = ReturnType<typeof useDeletePinMutation>;
export type DeletePinMutationResult = Apollo.MutationResult<DeletePinMutation>;
export type DeletePinMutationOptions = Apollo.BaseMutationOptions<DeletePinMutation, DeletePinMutationVariables>;
export const CreatePinDocument = gql`
    mutation createPin($placeId: ID!, $name: String!, $url: String!) {
  createPin(placeId: $placeId, name: $name, url: $url) {
    pin {
      id
    }
  }
}
    `;
export type CreatePinMutationFn = Apollo.MutationFunction<CreatePinMutation, CreatePinMutationVariables>;

/**
 * __useCreatePinMutation__
 *
 * To run a mutation, you first call `useCreatePinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPinMutation, { data, loading, error }] = useCreatePinMutation({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      name: // value for 'name'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCreatePinMutation(baseOptions?: Apollo.MutationHookOptions<CreatePinMutation, CreatePinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePinMutation, CreatePinMutationVariables>(CreatePinDocument, options);
      }
export type CreatePinMutationHookResult = ReturnType<typeof useCreatePinMutation>;
export type CreatePinMutationResult = Apollo.MutationResult<CreatePinMutation>;
export type CreatePinMutationOptions = Apollo.BaseMutationOptions<CreatePinMutation, CreatePinMutationVariables>;
export const GetPinDocument = gql`
    query getPin($id: ID!) {
  pins(id: $id) {
    id
    name
    url
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetPinQuery__
 *
 * To run a query within a React component, call `useGetPinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPinQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPinQuery(baseOptions: Apollo.QueryHookOptions<GetPinQuery, GetPinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPinQuery, GetPinQueryVariables>(GetPinDocument, options);
      }
export function useGetPinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPinQuery, GetPinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPinQuery, GetPinQueryVariables>(GetPinDocument, options);
        }
export type GetPinQueryHookResult = ReturnType<typeof useGetPinQuery>;
export type GetPinLazyQueryHookResult = ReturnType<typeof useGetPinLazyQuery>;
export type GetPinQueryResult = Apollo.QueryResult<GetPinQuery, GetPinQueryVariables>;
export function refetchGetPinQuery(variables?: GetPinQueryVariables) {
      return { query: GetPinDocument, variables: variables }
    }
export const GetPinNameDocument = gql`
    query getPinName($id: ID!) {
  pins(id: $id) {
    name
  }
}
    `;

/**
 * __useGetPinNameQuery__
 *
 * To run a query within a React component, call `useGetPinNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPinNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPinNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPinNameQuery(baseOptions: Apollo.QueryHookOptions<GetPinNameQuery, GetPinNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPinNameQuery, GetPinNameQueryVariables>(GetPinNameDocument, options);
      }
export function useGetPinNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPinNameQuery, GetPinNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPinNameQuery, GetPinNameQueryVariables>(GetPinNameDocument, options);
        }
export type GetPinNameQueryHookResult = ReturnType<typeof useGetPinNameQuery>;
export type GetPinNameLazyQueryHookResult = ReturnType<typeof useGetPinNameLazyQuery>;
export type GetPinNameQueryResult = Apollo.QueryResult<GetPinNameQuery, GetPinNameQueryVariables>;
export function refetchGetPinNameQuery(variables?: GetPinNameQueryVariables) {
      return { query: GetPinNameDocument, variables: variables }
    }
export const UpdatePinDocument = gql`
    mutation updatePin($id: ID!, $name: String!, $url: String!) {
  updatePin(id: $id, name: $name, url: $url) {
    pin {
      id
    }
  }
}
    `;
export type UpdatePinMutationFn = Apollo.MutationFunction<UpdatePinMutation, UpdatePinMutationVariables>;

/**
 * __useUpdatePinMutation__
 *
 * To run a mutation, you first call `useUpdatePinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePinMutation, { data, loading, error }] = useUpdatePinMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useUpdatePinMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePinMutation, UpdatePinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePinMutation, UpdatePinMutationVariables>(UpdatePinDocument, options);
      }
export type UpdatePinMutationHookResult = ReturnType<typeof useUpdatePinMutation>;
export type UpdatePinMutationResult = Apollo.MutationResult<UpdatePinMutation>;
export type UpdatePinMutationOptions = Apollo.BaseMutationOptions<UpdatePinMutation, UpdatePinMutationVariables>;
export const GetPlacesDocument = gql`
    query getPlaces($inactive: Boolean = false) {
  places(inactive: $inactive) {
    id
    name
    description
    walkSpeed
    placeType
    contact {
      id
      displayName
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetPlacesQuery__
 *
 * To run a query within a React component, call `useGetPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlacesQuery({
 *   variables: {
 *      inactive: // value for 'inactive'
 *   },
 * });
 */
export function useGetPlacesQuery(baseOptions?: Apollo.QueryHookOptions<GetPlacesQuery, GetPlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlacesQuery, GetPlacesQueryVariables>(GetPlacesDocument, options);
      }
export function useGetPlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlacesQuery, GetPlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlacesQuery, GetPlacesQueryVariables>(GetPlacesDocument, options);
        }
export type GetPlacesQueryHookResult = ReturnType<typeof useGetPlacesQuery>;
export type GetPlacesLazyQueryHookResult = ReturnType<typeof useGetPlacesLazyQuery>;
export type GetPlacesQueryResult = Apollo.QueryResult<GetPlacesQuery, GetPlacesQueryVariables>;
export function refetchGetPlacesQuery(variables?: GetPlacesQueryVariables) {
      return { query: GetPlacesDocument, variables: variables }
    }
export const DeletePlaceDocument = gql`
    mutation deletePlace($id: ID!) {
  deletePlace(id: $id) {
    success
  }
}
    `;
export type DeletePlaceMutationFn = Apollo.MutationFunction<DeletePlaceMutation, DeletePlaceMutationVariables>;

/**
 * __useDeletePlaceMutation__
 *
 * To run a mutation, you first call `useDeletePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceMutation, { data, loading, error }] = useDeletePlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePlaceMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceMutation, DeletePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlaceMutation, DeletePlaceMutationVariables>(DeletePlaceDocument, options);
      }
export type DeletePlaceMutationHookResult = ReturnType<typeof useDeletePlaceMutation>;
export type DeletePlaceMutationResult = Apollo.MutationResult<DeletePlaceMutation>;
export type DeletePlaceMutationOptions = Apollo.BaseMutationOptions<DeletePlaceMutation, DeletePlaceMutationVariables>;
export const HardDeletePlaceDocument = gql`
    mutation hardDeletePlace($id: ID!) {
  hardDeletePlace(id: $id) {
    success
  }
}
    `;
export type HardDeletePlaceMutationFn = Apollo.MutationFunction<HardDeletePlaceMutation, HardDeletePlaceMutationVariables>;

/**
 * __useHardDeletePlaceMutation__
 *
 * To run a mutation, you first call `useHardDeletePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHardDeletePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hardDeletePlaceMutation, { data, loading, error }] = useHardDeletePlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHardDeletePlaceMutation(baseOptions?: Apollo.MutationHookOptions<HardDeletePlaceMutation, HardDeletePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HardDeletePlaceMutation, HardDeletePlaceMutationVariables>(HardDeletePlaceDocument, options);
      }
export type HardDeletePlaceMutationHookResult = ReturnType<typeof useHardDeletePlaceMutation>;
export type HardDeletePlaceMutationResult = Apollo.MutationResult<HardDeletePlaceMutation>;
export type HardDeletePlaceMutationOptions = Apollo.BaseMutationOptions<HardDeletePlaceMutation, HardDeletePlaceMutationVariables>;
export const RestorePlaceDocument = gql`
    mutation restorePlace($id: ID!) {
  restorePlace(id: $id) {
    place {
      id
    }
  }
}
    `;
export type RestorePlaceMutationFn = Apollo.MutationFunction<RestorePlaceMutation, RestorePlaceMutationVariables>;

/**
 * __useRestorePlaceMutation__
 *
 * To run a mutation, you first call `useRestorePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestorePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restorePlaceMutation, { data, loading, error }] = useRestorePlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestorePlaceMutation(baseOptions?: Apollo.MutationHookOptions<RestorePlaceMutation, RestorePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestorePlaceMutation, RestorePlaceMutationVariables>(RestorePlaceDocument, options);
      }
export type RestorePlaceMutationHookResult = ReturnType<typeof useRestorePlaceMutation>;
export type RestorePlaceMutationResult = Apollo.MutationResult<RestorePlaceMutation>;
export type RestorePlaceMutationOptions = Apollo.BaseMutationOptions<RestorePlaceMutation, RestorePlaceMutationVariables>;
export const CreatePlaceDocument = gql`
    mutation createPlace($name: String!, $description: String!, $placeType: String!, $logoUrl: String, $walkSpeed: Float, $contactId: ID) {
  createPlace(
    name: $name
    description: $description
    placeType: $placeType
    logoUrl: $logoUrl
    walkSpeed: $walkSpeed
    contactId: $contactId
  ) {
    place {
      id
    }
  }
}
    `;
export type CreatePlaceMutationFn = Apollo.MutationFunction<CreatePlaceMutation, CreatePlaceMutationVariables>;

/**
 * __useCreatePlaceMutation__
 *
 * To run a mutation, you first call `useCreatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceMutation, { data, loading, error }] = useCreatePlaceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      placeType: // value for 'placeType'
 *      logoUrl: // value for 'logoUrl'
 *      walkSpeed: // value for 'walkSpeed'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useCreatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaceMutation, CreatePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaceMutation, CreatePlaceMutationVariables>(CreatePlaceDocument, options);
      }
export type CreatePlaceMutationHookResult = ReturnType<typeof useCreatePlaceMutation>;
export type CreatePlaceMutationResult = Apollo.MutationResult<CreatePlaceMutation>;
export type CreatePlaceMutationOptions = Apollo.BaseMutationOptions<CreatePlaceMutation, CreatePlaceMutationVariables>;
export const GetPlaceDocument = gql`
    query getPlace($id: ID!) {
  places(id: $id) {
    id
    name
    description
    walkSpeed
    placeType
    createdAt
    updatedAt
    logoUrl
    contactId
    pins {
      id
    }
  }
}
    `;

/**
 * __useGetPlaceQuery__
 *
 * To run a query within a React component, call `useGetPlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlaceQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceQuery, GetPlaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlaceQuery, GetPlaceQueryVariables>(GetPlaceDocument, options);
      }
export function useGetPlaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceQuery, GetPlaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlaceQuery, GetPlaceQueryVariables>(GetPlaceDocument, options);
        }
export type GetPlaceQueryHookResult = ReturnType<typeof useGetPlaceQuery>;
export type GetPlaceLazyQueryHookResult = ReturnType<typeof useGetPlaceLazyQuery>;
export type GetPlaceQueryResult = Apollo.QueryResult<GetPlaceQuery, GetPlaceQueryVariables>;
export function refetchGetPlaceQuery(variables?: GetPlaceQueryVariables) {
      return { query: GetPlaceDocument, variables: variables }
    }
export const GetPlaceNameDocument = gql`
    query getPlaceName($id: ID!) {
  places(id: $id) {
    name
  }
}
    `;

/**
 * __useGetPlaceNameQuery__
 *
 * To run a query within a React component, call `useGetPlaceNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlaceNameQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceNameQuery, GetPlaceNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlaceNameQuery, GetPlaceNameQueryVariables>(GetPlaceNameDocument, options);
      }
export function useGetPlaceNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceNameQuery, GetPlaceNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlaceNameQuery, GetPlaceNameQueryVariables>(GetPlaceNameDocument, options);
        }
export type GetPlaceNameQueryHookResult = ReturnType<typeof useGetPlaceNameQuery>;
export type GetPlaceNameLazyQueryHookResult = ReturnType<typeof useGetPlaceNameLazyQuery>;
export type GetPlaceNameQueryResult = Apollo.QueryResult<GetPlaceNameQuery, GetPlaceNameQueryVariables>;
export function refetchGetPlaceNameQuery(variables?: GetPlaceNameQueryVariables) {
      return { query: GetPlaceNameDocument, variables: variables }
    }
export const UpdatePlaceDocument = gql`
    mutation updatePlace($id: ID!, $name: String!, $description: String!, $placeType: String!, $logoUrl: String, $walkSpeed: Float, $contactId: ID) {
  updatePlace(
    id: $id
    name: $name
    description: $description
    placeType: $placeType
    logoUrl: $logoUrl
    walkSpeed: $walkSpeed
    contactId: $contactId
  ) {
    place {
      id
    }
  }
}
    `;
export type UpdatePlaceMutationFn = Apollo.MutationFunction<UpdatePlaceMutation, UpdatePlaceMutationVariables>;

/**
 * __useUpdatePlaceMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceMutation, { data, loading, error }] = useUpdatePlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      placeType: // value for 'placeType'
 *      logoUrl: // value for 'logoUrl'
 *      walkSpeed: // value for 'walkSpeed'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useUpdatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlaceMutation, UpdatePlaceMutationVariables>(UpdatePlaceDocument, options);
      }
export type UpdatePlaceMutationHookResult = ReturnType<typeof useUpdatePlaceMutation>;
export type UpdatePlaceMutationResult = Apollo.MutationResult<UpdatePlaceMutation>;
export type UpdatePlaceMutationOptions = Apollo.BaseMutationOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>;
export const ExportPlaceDocument = gql`
    mutation exportPlace($id: ID!) {
  exportPlace(id: $id) {
    data
  }
}
    `;
export type ExportPlaceMutationFn = Apollo.MutationFunction<ExportPlaceMutation, ExportPlaceMutationVariables>;

/**
 * __useExportPlaceMutation__
 *
 * To run a mutation, you first call `useExportPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportPlaceMutation, { data, loading, error }] = useExportPlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportPlaceMutation(baseOptions?: Apollo.MutationHookOptions<ExportPlaceMutation, ExportPlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportPlaceMutation, ExportPlaceMutationVariables>(ExportPlaceDocument, options);
      }
export type ExportPlaceMutationHookResult = ReturnType<typeof useExportPlaceMutation>;
export type ExportPlaceMutationResult = Apollo.MutationResult<ExportPlaceMutation>;
export type ExportPlaceMutationOptions = Apollo.BaseMutationOptions<ExportPlaceMutation, ExportPlaceMutationVariables>;
export const ImportPlaceDocument = gql`
    mutation importPlace($data: JSON!) {
  importPlace(data: $data) {
    id
  }
}
    `;
export type ImportPlaceMutationFn = Apollo.MutationFunction<ImportPlaceMutation, ImportPlaceMutationVariables>;

/**
 * __useImportPlaceMutation__
 *
 * To run a mutation, you first call `useImportPlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPlaceMutation, { data, loading, error }] = useImportPlaceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportPlaceMutation(baseOptions?: Apollo.MutationHookOptions<ImportPlaceMutation, ImportPlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportPlaceMutation, ImportPlaceMutationVariables>(ImportPlaceDocument, options);
      }
export type ImportPlaceMutationHookResult = ReturnType<typeof useImportPlaceMutation>;
export type ImportPlaceMutationResult = Apollo.MutationResult<ImportPlaceMutation>;
export type ImportPlaceMutationOptions = Apollo.BaseMutationOptions<ImportPlaceMutation, ImportPlaceMutationVariables>;
export const GetRoadsDocument = gql`
    query getRoads($id: ID!) {
  maps(id: $id) {
    roads {
      id
      name
      color
      width
      visible
      unidirectional
      distance
      createdAt
      updatedAt
      nodes {
        id
        pointGeo {
          latitude
          longitude
        }
      }
    }
  }
}
    `;

/**
 * __useGetRoadsQuery__
 *
 * To run a query within a React component, call `useGetRoadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoadsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoadsQuery(baseOptions: Apollo.QueryHookOptions<GetRoadsQuery, GetRoadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoadsQuery, GetRoadsQueryVariables>(GetRoadsDocument, options);
      }
export function useGetRoadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoadsQuery, GetRoadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoadsQuery, GetRoadsQueryVariables>(GetRoadsDocument, options);
        }
export type GetRoadsQueryHookResult = ReturnType<typeof useGetRoadsQuery>;
export type GetRoadsLazyQueryHookResult = ReturnType<typeof useGetRoadsLazyQuery>;
export type GetRoadsQueryResult = Apollo.QueryResult<GetRoadsQuery, GetRoadsQueryVariables>;
export function refetchGetRoadsQuery(variables?: GetRoadsQueryVariables) {
      return { query: GetRoadsDocument, variables: variables }
    }
export const GetRoadNameDocument = gql`
    query getRoadName($id: ID!) {
  roads(id: $id) {
    name
  }
}
    `;

/**
 * __useGetRoadNameQuery__
 *
 * To run a query within a React component, call `useGetRoadNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoadNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoadNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoadNameQuery(baseOptions: Apollo.QueryHookOptions<GetRoadNameQuery, GetRoadNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoadNameQuery, GetRoadNameQueryVariables>(GetRoadNameDocument, options);
      }
export function useGetRoadNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoadNameQuery, GetRoadNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoadNameQuery, GetRoadNameQueryVariables>(GetRoadNameDocument, options);
        }
export type GetRoadNameQueryHookResult = ReturnType<typeof useGetRoadNameQuery>;
export type GetRoadNameLazyQueryHookResult = ReturnType<typeof useGetRoadNameLazyQuery>;
export type GetRoadNameQueryResult = Apollo.QueryResult<GetRoadNameQuery, GetRoadNameQueryVariables>;
export function refetchGetRoadNameQuery(variables?: GetRoadNameQueryVariables) {
      return { query: GetRoadNameDocument, variables: variables }
    }
export const DeleteRoadDocument = gql`
    mutation deleteRoad($id: ID!) {
  deleteRoad(id: $id) {
    success
  }
}
    `;
export type DeleteRoadMutationFn = Apollo.MutationFunction<DeleteRoadMutation, DeleteRoadMutationVariables>;

/**
 * __useDeleteRoadMutation__
 *
 * To run a mutation, you first call `useDeleteRoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoadMutation, { data, loading, error }] = useDeleteRoadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoadMutation, DeleteRoadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoadMutation, DeleteRoadMutationVariables>(DeleteRoadDocument, options);
      }
export type DeleteRoadMutationHookResult = ReturnType<typeof useDeleteRoadMutation>;
export type DeleteRoadMutationResult = Apollo.MutationResult<DeleteRoadMutation>;
export type DeleteRoadMutationOptions = Apollo.BaseMutationOptions<DeleteRoadMutation, DeleteRoadMutationVariables>;
export const CreateRoadDocument = gql`
    mutation createRoad($mapId: ID!, $name: String!, $color: String!, $width: Int!, $visible: Boolean, $unidirectional: Boolean) {
  createRoad(
    mapId: $mapId
    name: $name
    color: $color
    width: $width
    visible: $visible
    unidirectional: $unidirectional
  ) {
    road {
      id
    }
  }
}
    `;
export type CreateRoadMutationFn = Apollo.MutationFunction<CreateRoadMutation, CreateRoadMutationVariables>;

/**
 * __useCreateRoadMutation__
 *
 * To run a mutation, you first call `useCreateRoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoadMutation, { data, loading, error }] = useCreateRoadMutation({
 *   variables: {
 *      mapId: // value for 'mapId'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *      width: // value for 'width'
 *      visible: // value for 'visible'
 *      unidirectional: // value for 'unidirectional'
 *   },
 * });
 */
export function useCreateRoadMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoadMutation, CreateRoadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoadMutation, CreateRoadMutationVariables>(CreateRoadDocument, options);
      }
export type CreateRoadMutationHookResult = ReturnType<typeof useCreateRoadMutation>;
export type CreateRoadMutationResult = Apollo.MutationResult<CreateRoadMutation>;
export type CreateRoadMutationOptions = Apollo.BaseMutationOptions<CreateRoadMutation, CreateRoadMutationVariables>;
export const UpdateRoadDocument = gql`
    mutation updateRoad($id: ID!, $name: String!, $color: String, $width: Int, $visible: Boolean, $unidirectional: Boolean) {
  updateRoad(
    id: $id
    name: $name
    color: $color
    width: $width
    visible: $visible
    unidirectional: $unidirectional
  ) {
    road {
      id
    }
  }
}
    `;
export type UpdateRoadMutationFn = Apollo.MutationFunction<UpdateRoadMutation, UpdateRoadMutationVariables>;

/**
 * __useUpdateRoadMutation__
 *
 * To run a mutation, you first call `useUpdateRoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoadMutation, { data, loading, error }] = useUpdateRoadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *      width: // value for 'width'
 *      visible: // value for 'visible'
 *      unidirectional: // value for 'unidirectional'
 *   },
 * });
 */
export function useUpdateRoadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoadMutation, UpdateRoadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoadMutation, UpdateRoadMutationVariables>(UpdateRoadDocument, options);
      }
export type UpdateRoadMutationHookResult = ReturnType<typeof useUpdateRoadMutation>;
export type UpdateRoadMutationResult = Apollo.MutationResult<UpdateRoadMutation>;
export type UpdateRoadMutationOptions = Apollo.BaseMutationOptions<UpdateRoadMutation, UpdateRoadMutationVariables>;
export const UpdateRoadNodesDocument = gql`
    mutation updateRoadNodes($roadId: ID!, $nodes: [[Float!]!]!, $unidirectional: Boolean!, $testIntermediateNodes: Boolean!) {
  updateRoadNodes(
    roadId: $roadId
    nodes: $nodes
    unidirectional: $unidirectional
    testIntermediateNodes: $testIntermediateNodes
  ) {
    nodes {
      id
    }
  }
}
    `;
export type UpdateRoadNodesMutationFn = Apollo.MutationFunction<UpdateRoadNodesMutation, UpdateRoadNodesMutationVariables>;

/**
 * __useUpdateRoadNodesMutation__
 *
 * To run a mutation, you first call `useUpdateRoadNodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoadNodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoadNodesMutation, { data, loading, error }] = useUpdateRoadNodesMutation({
 *   variables: {
 *      roadId: // value for 'roadId'
 *      nodes: // value for 'nodes'
 *      unidirectional: // value for 'unidirectional'
 *      testIntermediateNodes: // value for 'testIntermediateNodes'
 *   },
 * });
 */
export function useUpdateRoadNodesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoadNodesMutation, UpdateRoadNodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoadNodesMutation, UpdateRoadNodesMutationVariables>(UpdateRoadNodesDocument, options);
      }
export type UpdateRoadNodesMutationHookResult = ReturnType<typeof useUpdateRoadNodesMutation>;
export type UpdateRoadNodesMutationResult = Apollo.MutationResult<UpdateRoadNodesMutation>;
export type UpdateRoadNodesMutationOptions = Apollo.BaseMutationOptions<UpdateRoadNodesMutation, UpdateRoadNodesMutationVariables>;
export const CreateRoadFromFileDocument = gql`
    mutation createRoadFromFile($mapId: ID!, $name: String!, $color: String!, $width: Int!, $unidirectional: Boolean!, $gpxFile: String!) {
  createRoadFromFile(
    mapId: $mapId
    name: $name
    color: $color
    width: $width
    unidirectional: $unidirectional
    gpxFile: $gpxFile
  ) {
    nodes {
      id
    }
  }
}
    `;
export type CreateRoadFromFileMutationFn = Apollo.MutationFunction<CreateRoadFromFileMutation, CreateRoadFromFileMutationVariables>;

/**
 * __useCreateRoadFromFileMutation__
 *
 * To run a mutation, you first call `useCreateRoadFromFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoadFromFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoadFromFileMutation, { data, loading, error }] = useCreateRoadFromFileMutation({
 *   variables: {
 *      mapId: // value for 'mapId'
 *      name: // value for 'name'
 *      color: // value for 'color'
 *      width: // value for 'width'
 *      unidirectional: // value for 'unidirectional'
 *      gpxFile: // value for 'gpxFile'
 *   },
 * });
 */
export function useCreateRoadFromFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoadFromFileMutation, CreateRoadFromFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoadFromFileMutation, CreateRoadFromFileMutationVariables>(CreateRoadFromFileDocument, options);
      }
export type CreateRoadFromFileMutationHookResult = ReturnType<typeof useCreateRoadFromFileMutation>;
export type CreateRoadFromFileMutationResult = Apollo.MutationResult<CreateRoadFromFileMutation>;
export type CreateRoadFromFileMutationOptions = Apollo.BaseMutationOptions<CreateRoadFromFileMutation, CreateRoadFromFileMutationVariables>;
export const GetRolesDocument = gql`
    query getRoles($placeId: ID!) {
  roles(placeId: $placeId) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export function refetchGetRolesQuery(variables?: GetRolesQueryVariables) {
      return { query: GetRolesDocument, variables: variables }
    }
export const GetAllRolesDocument = gql`
    query getAllRoles {
  roles {
    id
    name
    createdAt
    updatedAt
    placeId
  }
}
    `;

/**
 * __useGetAllRolesQuery__
 *
 * To run a query within a React component, call `useGetAllRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRolesQuery, GetAllRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRolesQuery, GetAllRolesQueryVariables>(GetAllRolesDocument, options);
      }
export function useGetAllRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRolesQuery, GetAllRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRolesQuery, GetAllRolesQueryVariables>(GetAllRolesDocument, options);
        }
export type GetAllRolesQueryHookResult = ReturnType<typeof useGetAllRolesQuery>;
export type GetAllRolesLazyQueryHookResult = ReturnType<typeof useGetAllRolesLazyQuery>;
export type GetAllRolesQueryResult = Apollo.QueryResult<GetAllRolesQuery, GetAllRolesQueryVariables>;
export function refetchGetAllRolesQuery(variables?: GetAllRolesQueryVariables) {
      return { query: GetAllRolesDocument, variables: variables }
    }
export const GetRoleDocument = gql`
    query getRole($id: ID!) {
  roles(id: $id) {
    id
    name
    permissions
  }
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
      }
export function useGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, options);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export function refetchGetRoleQuery(variables?: GetRoleQueryVariables) {
      return { query: GetRoleDocument, variables: variables }
    }
export const GetRoleNameDocument = gql`
    query getRoleName($id: ID!) {
  roles(id: $id) {
    name
  }
}
    `;

/**
 * __useGetRoleNameQuery__
 *
 * To run a query within a React component, call `useGetRoleNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleNameQuery(baseOptions: Apollo.QueryHookOptions<GetRoleNameQuery, GetRoleNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleNameQuery, GetRoleNameQueryVariables>(GetRoleNameDocument, options);
      }
export function useGetRoleNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleNameQuery, GetRoleNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleNameQuery, GetRoleNameQueryVariables>(GetRoleNameDocument, options);
        }
export type GetRoleNameQueryHookResult = ReturnType<typeof useGetRoleNameQuery>;
export type GetRoleNameLazyQueryHookResult = ReturnType<typeof useGetRoleNameLazyQuery>;
export type GetRoleNameQueryResult = Apollo.QueryResult<GetRoleNameQuery, GetRoleNameQueryVariables>;
export function refetchGetRoleNameQuery(variables?: GetRoleNameQueryVariables) {
      return { query: GetRoleNameDocument, variables: variables }
    }
export const UpdateRoleDocument = gql`
    mutation updateRole($id: ID!, $permissions: [String!]!) {
  updateRole(id: $id, permissions: $permissions) {
    role {
      id
    }
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const GetSchedulesDocument = gql`
    query getSchedules($id: ID!) {
  locations(id: $id) {
    schedules {
      id
      timeRange
      dayOfTheWeek
      specificDay
      active
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetSchedulesQuery__
 *
 * To run a query within a React component, call `useGetSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchedulesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSchedulesQuery(baseOptions: Apollo.QueryHookOptions<GetSchedulesQuery, GetSchedulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchedulesQuery, GetSchedulesQueryVariables>(GetSchedulesDocument, options);
      }
export function useGetSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchedulesQuery, GetSchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchedulesQuery, GetSchedulesQueryVariables>(GetSchedulesDocument, options);
        }
export type GetSchedulesQueryHookResult = ReturnType<typeof useGetSchedulesQuery>;
export type GetSchedulesLazyQueryHookResult = ReturnType<typeof useGetSchedulesLazyQuery>;
export type GetSchedulesQueryResult = Apollo.QueryResult<GetSchedulesQuery, GetSchedulesQueryVariables>;
export function refetchGetSchedulesQuery(variables?: GetSchedulesQueryVariables) {
      return { query: GetSchedulesDocument, variables: variables }
    }
export const DeleteScheduleDocument = gql`
    mutation deleteSchedule($id: ID!) {
  deleteSchedule(id: $id) {
    success
  }
}
    `;
export type DeleteScheduleMutationFn = Apollo.MutationFunction<DeleteScheduleMutation, DeleteScheduleMutationVariables>;

/**
 * __useDeleteScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduleMutation, { data, loading, error }] = useDeleteScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScheduleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScheduleMutation, DeleteScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScheduleMutation, DeleteScheduleMutationVariables>(DeleteScheduleDocument, options);
      }
export type DeleteScheduleMutationHookResult = ReturnType<typeof useDeleteScheduleMutation>;
export type DeleteScheduleMutationResult = Apollo.MutationResult<DeleteScheduleMutation>;
export type DeleteScheduleMutationOptions = Apollo.BaseMutationOptions<DeleteScheduleMutation, DeleteScheduleMutationVariables>;
export const CreateScheduleDocument = gql`
    mutation createSchedule($dayOfTheWeek: String, $specificDay: String, $timeRange: String!, $active: Boolean!, $locationId: ID!) {
  createSchedule(
    dayOfTheWeek: $dayOfTheWeek
    specificDay: $specificDay
    timeRange: $timeRange
    active: $active
    locationId: $locationId
  ) {
    schedule {
      id
    }
  }
}
    `;
export type CreateScheduleMutationFn = Apollo.MutationFunction<CreateScheduleMutation, CreateScheduleMutationVariables>;

/**
 * __useCreateScheduleMutation__
 *
 * To run a mutation, you first call `useCreateScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleMutation, { data, loading, error }] = useCreateScheduleMutation({
 *   variables: {
 *      dayOfTheWeek: // value for 'dayOfTheWeek'
 *      specificDay: // value for 'specificDay'
 *      timeRange: // value for 'timeRange'
 *      active: // value for 'active'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useCreateScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CreateScheduleMutation, CreateScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScheduleMutation, CreateScheduleMutationVariables>(CreateScheduleDocument, options);
      }
export type CreateScheduleMutationHookResult = ReturnType<typeof useCreateScheduleMutation>;
export type CreateScheduleMutationResult = Apollo.MutationResult<CreateScheduleMutation>;
export type CreateScheduleMutationOptions = Apollo.BaseMutationOptions<CreateScheduleMutation, CreateScheduleMutationVariables>;
export const GetScheduleDocument = gql`
    query getSchedule($id: ID!) {
  schedules(id: $id) {
    id
    timeRange
    dayOfTheWeek
    specificDay
    active
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetScheduleQuery__
 *
 * To run a query within a React component, call `useGetScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScheduleQuery(baseOptions: Apollo.QueryHookOptions<GetScheduleQuery, GetScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduleQuery, GetScheduleQueryVariables>(GetScheduleDocument, options);
      }
export function useGetScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduleQuery, GetScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduleQuery, GetScheduleQueryVariables>(GetScheduleDocument, options);
        }
export type GetScheduleQueryHookResult = ReturnType<typeof useGetScheduleQuery>;
export type GetScheduleLazyQueryHookResult = ReturnType<typeof useGetScheduleLazyQuery>;
export type GetScheduleQueryResult = Apollo.QueryResult<GetScheduleQuery, GetScheduleQueryVariables>;
export function refetchGetScheduleQuery(variables?: GetScheduleQueryVariables) {
      return { query: GetScheduleDocument, variables: variables }
    }
export const GetScheduleDayDocument = gql`
    query getScheduleDay($id: ID!) {
  schedules(id: $id) {
    dayOfTheWeek
  }
}
    `;

/**
 * __useGetScheduleDayQuery__
 *
 * To run a query within a React component, call `useGetScheduleDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleDayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScheduleDayQuery(baseOptions: Apollo.QueryHookOptions<GetScheduleDayQuery, GetScheduleDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduleDayQuery, GetScheduleDayQueryVariables>(GetScheduleDayDocument, options);
      }
export function useGetScheduleDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduleDayQuery, GetScheduleDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduleDayQuery, GetScheduleDayQueryVariables>(GetScheduleDayDocument, options);
        }
export type GetScheduleDayQueryHookResult = ReturnType<typeof useGetScheduleDayQuery>;
export type GetScheduleDayLazyQueryHookResult = ReturnType<typeof useGetScheduleDayLazyQuery>;
export type GetScheduleDayQueryResult = Apollo.QueryResult<GetScheduleDayQuery, GetScheduleDayQueryVariables>;
export function refetchGetScheduleDayQuery(variables?: GetScheduleDayQueryVariables) {
      return { query: GetScheduleDayDocument, variables: variables }
    }
export const UpdateScheduleDocument = gql`
    mutation updateSchedule($id: ID!, $dayOfTheWeek: String, $specificDay: String, $timeRange: String!, $active: Boolean!, $locationId: ID!) {
  updateSchedule(
    id: $id
    dayOfTheWeek: $dayOfTheWeek
    specificDay: $specificDay
    timeRange: $timeRange
    active: $active
    locationId: $locationId
  ) {
    schedule {
      id
    }
  }
}
    `;
export type UpdateScheduleMutationFn = Apollo.MutationFunction<UpdateScheduleMutation, UpdateScheduleMutationVariables>;

/**
 * __useUpdateScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleMutation, { data, loading, error }] = useUpdateScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dayOfTheWeek: // value for 'dayOfTheWeek'
 *      specificDay: // value for 'specificDay'
 *      timeRange: // value for 'timeRange'
 *      active: // value for 'active'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useUpdateScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScheduleMutation, UpdateScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScheduleMutation, UpdateScheduleMutationVariables>(UpdateScheduleDocument, options);
      }
export type UpdateScheduleMutationHookResult = ReturnType<typeof useUpdateScheduleMutation>;
export type UpdateScheduleMutationResult = Apollo.MutationResult<UpdateScheduleMutation>;
export type UpdateScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateScheduleMutation, UpdateScheduleMutationVariables>;
export const GetUsersDocument = gql`
    query getUsers {
  users {
    uid
    email
    name
    lastName
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export function refetchGetUsersQuery(variables?: GetUsersQueryVariables) {
      return { query: GetUsersDocument, variables: variables }
    }
export const GetUserDocument = gql`
    query getUser($uid: String!) {
  users(uid: $uid) {
    uid
    email
    phone
    name
    lastName
    gender
    createdAt
    updatedAt
    roles {
      id
      name
      placeId
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export function refetchGetUserQuery(variables?: GetUserQueryVariables) {
      return { query: GetUserDocument, variables: variables }
    }
export const GetUserEmailDocument = gql`
    query getUserEmail($uid: String!) {
  users(uid: $uid) {
    email
  }
}
    `;

/**
 * __useGetUserEmailQuery__
 *
 * To run a query within a React component, call `useGetUserEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEmailQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetUserEmailQuery(baseOptions: Apollo.QueryHookOptions<GetUserEmailQuery, GetUserEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEmailQuery, GetUserEmailQueryVariables>(GetUserEmailDocument, options);
      }
export function useGetUserEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEmailQuery, GetUserEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEmailQuery, GetUserEmailQueryVariables>(GetUserEmailDocument, options);
        }
export type GetUserEmailQueryHookResult = ReturnType<typeof useGetUserEmailQuery>;
export type GetUserEmailLazyQueryHookResult = ReturnType<typeof useGetUserEmailLazyQuery>;
export type GetUserEmailQueryResult = Apollo.QueryResult<GetUserEmailQuery, GetUserEmailQueryVariables>;
export function refetchGetUserEmailQuery(variables?: GetUserEmailQueryVariables) {
      return { query: GetUserEmailDocument, variables: variables }
    }
export const UpdateUserDocument = gql`
    mutation updateUser($uid: String!, $email: String, $phone: String, $name: String, $lastName: String, $gender: String, $roles: [ID!]) {
  updateUser(
    uid: $uid
    email: $email
    phone: $phone
    name: $name
    lastName: $lastName
    gender: $gender
    roles: $roles
  ) {
    user {
      uid
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      name: // value for 'name'
 *      lastName: // value for 'lastName'
 *      gender: // value for 'gender'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($email: String!, $password: String!) {
  register(email: $email, password: $password) {
    registerUser {
      uid
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($uid: String!) {
  deleteUser(uid: $uid) {
    success
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
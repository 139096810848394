export default {
  featuresDescription:
    'A location is used to define the position of a point of interest, which will be displayed as a point of interest pin on the selected map.',
  feature: 'feature',
  features: 'features',
  center: 'center',
  priority: 'priority',
  priorityDescription:
    'Used to define the zoom level the pin will be seen at, where "High" means it will be seen even at the farthest zoom and "Low" will only be seen at maximum zoom',
  toZoom: 'hidden on zoom',
  toZoomDescription:
    'Used to hide the pin from the selected zoom, where "High" means it will be hidden after zooming in to the farthest location and "Low" means it will never be hidden',
  toZoomMustBeGreaterThanFromZoom: '"Hidden on zoom" must always be lower than the priority',
};

export default {
  featuresDescription:
    'Una ubicación es usada para definir la posición de un punto de interés, la cual se mostrará como un pin del punto de interés en el mapa seleccionado.',
  feature: 'ubicación',
  features: 'ubicaciones',
  center: 'centro',
  priority: 'prioridad',
  priorityDescription:
    'Usada para definir el nivel de zoom en que se verá el pin, donde "Alta" significa que se vera incluso con el zoom más lejano y "Baja" solo se verá con el zoom máximo',
  fromZoom: 'Visible desde zoom',
  toZoom: 'Dejar de mostrar en zoom',
  toZoomDescription:
    'Usada para ocultar el pin desde el zoom seleccionado, donde "Alta" significa que se ocultará después de acercar el zoom más lejano y "Baja" no se ocultará nunca. Por ejemplo, si se tiene una ubicación de un punto de interés que se solo quiero que se muestre en el zoom máximo, para demarcar una zona. Se puede seleccionar "Alta" y cuando una persona, desde la aplicación haga zoom, no podrá ver la ubicación, sino que se mostrarán solo las otras ubicaciones.',
  toZoomMustBeGreaterThanFromZoom: '"Dejar de mostrar en zoom" siembre debe ser menor a la prioridad',
};

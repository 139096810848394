import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Skeleton,
  TextField,
  Button,
} from '@mui/material';
import { capitalize } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';

const DynamicList = (props: any) => {
  const { objectList, loading, columns, search, actions, description, onRowClick } = props;
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [urlParams] = useSearchParams();
  const searchParam = urlParams.get('search');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  if ((objectList || [])[0] && objectList[0].name) {
    objectList?.sort((a: any, b: any) => {
     if (a.name < b.name) {
       return -1;
     }
     if (a.name > b.name) {
       return 1;
     }
     return 0;
   });
  }


  const handlePageChange = (event: any, page: any) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(event.target.value);
  };

  useEffect(() => {
    setSearchTerm(searchParam);
  }, [urlParams]);

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event: any) => {
    event.preventDefault();
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('search', searchTerm);
    navigate({ search: queryParams.toString() });
    search(searchTerm);
  };

  return (
    <Card>
      {description && (
        <CardContent
          sx={{
            textAlign: 'left',
            fontSize: 14,
            color: '#aaa',
            fontWeight: 500,
          }}
        >
          {description}
        </CardContent>
      )}
      {search && (
        <CardContent>
          <form onSubmit={handleSearchSubmit}>
            <TextField
              label={t('search')}
              value={searchTerm}
              onChange={handleSearchChange}
              variant="outlined"
              size="small"
              sx={{ marginRight: 1 }}
            />
            <Button type="submit" variant="contained" color="primary">
              {t('search')}
            </Button>
          </form>
        </CardContent>
      )}
      <CardActions>{actions}</CardActions>
      <CardContent sx={{ padding: 0, overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column: any, index: number) => (
                <TableCell key={column.label} sx={index === columns.length - 1 ? { position: 'sticky', right: 0, background: '#fff', zIndex: 1 } : {}}>
                  {capitalize(column.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || !objectList ? (
              <TableRow>
                {columns.map((column: any, index: number) => (
                  <TableCell key={`skeleton_${column.label}`} sx={index === columns.length - 1 ? { position: 'sticky', right: 0, background: '#fff' } : {}}>
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="40%" />
                  </TableCell>
                ))}
              </TableRow>
            ) : (
              objectList
                .slice(
                  rowsPerPage * page,
                  objectList.length < rowsPerPage * (page + 1) ? objectList.length : rowsPerPage * (page + 1),
                )
                .map((object: any) => (
                  <TableRow hover key={object.id} sx={onRowClick ? { cursor: 'pointer' } : {}}>
                    {columns.map((column: any, index: number) => (
                      <TableCell
                        key={`${object.id}_${column.label}`}
                        sx={index === columns.length - 1 ? { position: 'sticky', right: 0, background: '#fff' } : {}}
                        onClick={() => column.label !== t('actions') && onRowClick && onRowClick(object)}
                      >
                        {column.renderFn(object)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <TablePagination
          component="div"
          count={objectList?.length || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

export default DynamicList;
